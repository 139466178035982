import axios, { AxiosRequestConfig } from "axios";

type RequestTypes =
  | "Requested"
  | "Initiated"
  | "Approved"
  | "Completed"
  | "Rejected";

export const SanitizationRequestGetByStatus = async (
  startDate: string,
  endDate: string,
  type: RequestTypes,
  key: string
) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url:
      "https://api.cca.distronix.in:3443/caller/sanitizationRequestGetByStatus/" +
      type,
    headers: {
      authorization: key,
    },
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  };
  const result = await axios(config)
    .then((res) => ({ data: res.data, success: true, error: "" }))
    .catch((e) => ({ error: e, success: false, data: [] }));
  console.log(result);
  return result;
};
