




import React, { Component } from 'react';
import { Card, CardDeck } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'

//import moment from 'moment';
import './pdf.css'


function Cards(props:any) {



  return (
    
    
    <>
    
      {/* <div className='dates'>
        <div className='start_date' style={{ textAlign:'center', color: 'green', marginLeft: '20%', fontWeight: 'bold' }}>
           Date : 10-05-2021
    </div>
       
      </div><br />
      */}
    <div className="Header_deisolation">
    <img className= 'logo_deisolation' src='/logo.png' alt='' />
    <div className="Title_deisolation">
      <p >NEWTOWN KOLKATA DEVELOPMENT AUTHORITY</p>
      </div>
           <img className= 'logo2_deisolation' src='/logo2.png' alt='' />
    </div>
    <div className="Header2_deisolation">
    <div className="Date_deisolation">
      <p style={{fontWeight:'bold'}}>DATE : 21/05/2021</p>
      </div>
    <div className="Title2_deisolation">
      <p>Patient Master Data Sheet</p>
      </div>
     
      <div className="Time_deisolation">
      <p style={{fontWeight:'bold'}}>TIME : 10:45 A.M</p>
      </div>
        </div>
      <div className='total' style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold',fontSize: 30,marginTop:'60px' }}>
        Total Number Of Patients: {props.data.size}
    </div>
      <div>
        {

          props.data ?
            <div>
              <table className="table">
                <thead  style={{color:'#ff7b03',backgroundColor: '#e7e5e5',textAlign:'center'}}>
                  <tr style={{fontWeight:"bold",fontSize:'larger'}}>
                    {/* <th>Id</th> */}
                    
                    <th>Collection Date</th>

                    <th>

                    

                    Patient Name</th>
                    <th>Number</th>
                    <th>Sex</th>
                    <th>Age</th>
                    <th>Status</th>
                    <th>Address</th>
                    <th>Action Area</th>
                    <th>Building</th>
                    
                  </tr>
                </thead>
                <tbody style={{fontWeight:'lighter',textAlign:'center'}}>
                  {props.data.data.map(
                    (data:any, i:number) => {

                      var status = data[props.data.tableHeader[5]]

                      return <tr style={{ color: 
                        
                        status==='ACTIVE' ? "#999900"
                      : status==='CURED' ? "green"
                      :  "red" }}>
                        {/* <td>{data.id}</td> */}
                        <td>{data[props.data.tableHeader[0]]} </td>
                        <td> {data[props.data.tableHeader[1]]}</td>
                        <td> {data[props.data.tableHeader[2]]}</td>
                        <td> {data[props.data.tableHeader[3]]}</td>
                        <td> {data[props.data.tableHeader[4]]}</td>
                        <td> {data[props.data.tableHeader[5]]}</td>
                        <td> {data[props.data.tableHeader[6]]}</td>
                        <td> {data[props.data.tableHeader[7]]}</td>
                        <td> {data[props.data.tableHeader[8]]}</td>
                      </tr>
                    }
                  )}
                </tbody>
              </table>
            </div>

            : <p>Please Wait</p>
        }
      </div>
  
    </>
  
  )
}
class PdfReport extends Component {
  render() {
    return (
      <div>
        < Cards data={this.props} />
      </div>
    );
  }
}

export default PdfReport;
