import { Content } from "antd/lib/layout/layout";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import NewsFeedGrid from "./NewsFeedGrid";
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import "./styles.css";
import { _createNewsFeed, getNewsFeeds } from "../../api/NewsFeedAPIs/NewsFeed";
type Props = {};

const DUMMY_DATA = [
  {
    id: 1,
    heading: "We Are all affected",
    Description: "Please stay in home, Your health is infected by air",
    Image:
      "https://plus.unsplash.com/premium_photo-1686000531905-73dde137bc91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=327&q=80",
    action: "",
  },
];

const _readData = () => {};
const NewsFeed = (props: Props) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [imgObject, setImgObj] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedImage(file ? URL.createObjectURL(file) : null);

    setImgObj(file);
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const _getData = async () => {
    const res = await getNewsFeeds();

    if (res?.status === 200) {
      // Then set Data

      const results = res?.data?.data;

      const items = results.map((item: any, index: number) => {
        return {
          id: index + 1,
          heading: item?.event_title,
          Description: item?.description,
          Image: item?.image_url,
          uid: item?.id,
        };
      });

      setData(items);
    }
  };

  useEffect(() => {
    _getData();
  }, []);

  const titlteRef = useRef<any>(null);
  const desRef = useRef<any>(null);

  const _submitHandler =async () => {
    const res = await _createNewsFeed({
      event_title: titlteRef?.current?.value,
      description: desRef?.current?.value,
      file: imgObject,
    });

    if (res?.status === 200) {
      titlteRef.current.value = null;
      desRef.current.value = null;
      setSelectedImage(null);
      alert(res?.data?.message);
      setIsOpen(false);
      _getData();
    } else {
      setIsOpen(false);
      alert("Something went wrong");
    }
  };

  return (
    <Content style={{ margin: "0 16px", flex: 1 }}>
      {/* Modal for create new feed item start*/}
      <Modal
        show={isOpen}
        style={{ width: "100%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        onHide={() => setIsOpen(false)}
      >
        <div
          style={{
            width: "800px",
            height: "600px",
            backgroundColor: "#2d334a",
          }}
        >
          <h1 className=" tracking-wider text-white font-semibold text-xl px-4 py-2 text-center">
            Create News Feed
          </h1>
          <div className="w-full flex flex-col">
            {/* heading section  */}
            <h1 className="text-white text-lg px-4 py-2 font-normal">Title</h1>
            <input
              className=" grow  h-10 mx-4 text-lg rounded-md px-2"
              ref={titlteRef}
            />
          </div>
          <div className="w-full flex flex-col">
            {/* Decription section  */}
            <h1 className="text-white text-lg px-4 py-2 font-normal">
              Description
            </h1>
            <textarea
              className="mx-4 text-lg rounded-md px-2 py-2"
              rows={7}
              ref={desRef}
            />
          </div>
          <div className="ml-4 flex flex-row  rounded-xl my-3 items-center justify-center">
            <div className="h-32 w-48 rounded-xl bg-[#1e1e1e] border-2 overflow-hidden">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Uploaded Image"
                  className="rounded-xl h-32 w-48 object-fill "
                />
              )}
            </div>
            <div className="">
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className="mx-4 px-4 text-md text-white"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <div
                className="bg-[#fff] p-2 text-lg rounded-lg cursor-pointer m-2"
                onClick={handleButtonClick}
              >
                Upload Image
              </div>
            </div>
          </div>

          {/* Btn section */}
          <div className="float-right flex ">
            <div
              className="bg-[#ff0000] cursor-pointer p-2 text-lg rounded-lg mx-2"
              onClick={() => setIsOpen(false)}
            >
              Close
            </div>
            <div
              className="bg-[#4ca443] cursor-pointer p-2 text-lg rounded-lg mr-2 text-white"
              onClick={_submitHandler}
            >
              Submit
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal for create new feed item end*/}
      {/* Heading  Start*/}
      <div
        className="flex py-2 -mb-4  justify-end "
        onClick={() => setIsOpen(true)}
      >
        <h1 className=" bg-[#fff] p-2 rounded-lg cursor-pointer">
          Add News Feed
        </h1>
      </div>
      {/* Heading end */}
      {/* News feed start */}
      <NewsFeedGrid onClick={_getData} data={data} />
      {/* News feed end */}
    </Content>
  );
};

export default NewsFeed;

// className=" grow  h-8 mx-4 text-lg rounded-md px-2"
