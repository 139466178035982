import { Field } from "formik";
import React, { useEffect, useState } from "react";

type AppProps = {
  options: string[];
  name: string;
  label: string;
  value?: any;
  id?: string;
  addnew?: any;
  index?: any;
  set?: any;
  deleted?: any;
  refresh?: any;
};

const DropdownGrid: React.FC<{ props: AppProps }> = ({ props }) => {
    const [enabledId, setEnabledId] = useState<any>([])

  useEffect(()=> {
    props?.refresh ? setEnabledId([]) : setEnabledId(props?.index)
  // props?.index?.forEach((item: any)=> {
  //   props?.set?.forEach((v: any)=> {
  //     if(item === v && props?.refresh === false) {
  //       setEnabledId((prev:any) => [...prev, v] ) ;
  //     }
  //   })
  //     // item === props?.id && setEnabledId((prev:any) => [...prev, item] ) ;
  // })
  // console.log(props?.addnew)
  
    },[props?.index])
    
  useEffect(() => {
    props?.refresh === true && setEnabledId([])
  },[props?.refresh])
  // console.log(enabledId)
  // const valuedItemOptions = props.options.map((item) =>(

  //   item === props?.value && <option value={item}>{item}</option>
  // ))
//   console.log(props?.name)
    const itemOptions = props?.options?.map((item : any, key) => {
        // console.log(item)
       return  <option value={item}>{item}</option>
    });
  return (
    <div>
      <label className="font-normal text-xs tracking-wide">
          {props.label}
      </label>
      {/* {!props.value ?  */}
      <Field
        name={props.name}
        as="select"
        id={props.id}
        disabled={(((enabledId?.includes(props?.id) && !props?.deleted?.has(props?.id))&& props?.refresh=== false )|| (props?.addnew?.includes(props?.id)))? false :  true}
        className={props?.deleted?.has(props?.id) ? "w-full bg-[#F4F2FF] border-gray-300  rounded border focus:ring-1 focus:ring-indigo-800 text-sm outline-none disabled:border-0  text-gray-700 p-2 leading-8 required:":"sm:w-full w-fit bg-white border-gray-300  rounded border focus:ring-1 focus:ring-indigo-800 text-sm outline-none disabled:border-0  text-gray-700 p-2 leading-8 required:"}
      >
        <option value={""}>Select Option</option>
        {itemOptions}
      </Field> 
    </div>
  );
};

export default DropdownGrid;