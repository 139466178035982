import axios, { AxiosRequestConfig } from "axios";
import FormData from 'form-data';

export const excelUpload = async (file: any, key: string) => {
    var data = new FormData();
    data.append("file", file);
  var config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.cca.distronix.in:3443/caller/excel/upload",
    headers: {
      "Content-Type": "multipart/form-data",
      "authorization": key
    },
    data: data,
  };
  const result = await axios(config)
    .then((res) => ({ data: res.data, success: true, error: "" }))
    .catch((e) => ({ error: e, success: false, data: undefined }));
  console.log(result);
  return result;
};
