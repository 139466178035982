import {
    faCheckCircle,
    faCircleNotch,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import moment from "moment";
  import React, { useState } from "react";
  import { Button, ButtonGroup, Col, Form } from "react-bootstrap";
  import { useSelector } from "react-redux";
  import {
    addSinglePatient,
    ISinglePatientFormData,
  } from "../../api/addSinglePatient";

  import { Card, CardDeck } from 'react-bootstrap'
  
  export const ShowPatientForm: React.FC<{ closeView: () => void; data: ISinglePatientFormData }> = ({
    closeView = () => { },
    data,
}) => {
//   var sample_collection_date :any =  moment(data.sample_collection_date).format('dddd Do MMMM YYYY');

  return(
    <CardDeck style={{display:'content'}}>
     
    <Card style={{ backgroundColor: '#fff', borderColor: 'black', width: '100%', height: 'auto', }} >
      <Card.Body>
      <div>
  <table style={{width:'100%'}}>
 <thead>
  
<tr>
<th></th>
<th></th> 
<th></th>
<th></th>
</tr>
</thead>
<tbody>
<>
<tr>
<td style={{fontWeight:'bold'}}>1.ID :</td> 
<td></td>   
<td style={{fontWeight:'bold'}}>2.Patient Name :</td>
<td>{data?.name}</td>

</tr>
<tr>
<td style={{fontWeight:'bold'}}>3.Age :</td>
<td>{data?.age}</td>
<td style={{fontWeight:'bold'}}>4.Sex :</td>
<td>{data?.sex}</td>
</tr>
<tr >
<td style={{fontWeight:'bold'}}>5.Group :</td>
<td></td>
<td style={{fontWeight:'bold'}}>6.Case Id :</td>
<td></td>
</tr>
<tr>

<td style={{fontWeight:'bold'}}>7.Status :</td>
<td>{data?.status}</td>
</tr>



{/* <tr>
<td style={{fontWeight:'bold'}}>7.Building :</td>
<td>{data.building}</td>
<td style={{fontWeight:'bold'}}>8.Plot :</td>
<td>{data.plot_no}</td>

</tr> */}
<tr>
<td style={{fontWeight:'bold'}}>8.Last Onlined :</td>
<td></td>
<td style={{fontWeight:'bold'}}>9.Last Called :</td>
<td></td>
</tr>

</>
</tbody>
</table>
</div>
<Button
        style={{ marginTop:'20px',marginLeft:'45%' }}
        variant="danger"
        onClick={() => {
          closeView();
        }}
      >
        Close
      </Button>

      </Card.Body>
    </Card>
  </CardDeck>




    );
};


  export default ShowPatientForm
