import React, { useState } from "react";
import { Button, ButtonGroup, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { excelUpload } from "../../api/excelUpload";
import { RootState } from "../../app/store";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
//@ts-ignore
import CSVTemplate from "../../assets/templetes/UploadPatient.csv";

export const UploadMultiplePatients: React.FC<{
  closeView: () => void;
  showView: () => void;
}> = ({ closeView = () => {}, showView = () => {} }) => {
  const [ExcelFile, setExcelFile] = useState();
  const [ShowLoader, setShowLoader] = useState(false);
  const { accessToken } = useSelector((state: RootState) => state.user);
  const fileUpload = (accessToken: string) => {
    setShowLoader(true);
    excelUpload(ExcelFile, accessToken)
      .then((r) => {
        setShowLoader(false);
        console.log(r.data);
        const { status, citizens, cases } = r.data;
        const msg =
          status === "success"
            ? `${citizens} citizen records created. ${cases} new cases added.`
            : "Bad format or data in CSV: " + status;
        closeView();
        confirmAlert({
          title: "Data Upload Summary",
          message: msg,
          buttons: [
            {
              label: "Close",
              onClick: () => {},
            },
            {
              label: "Retry",
              onClick: () => {
                setExcelFile(undefined);
                showView();
              },
            },
          ],
        });
      })
      .catch((e) => {
        setShowLoader(false);
        confirmAlert({
          title: "Data Upload Summary",
          message: "File not uploaded. " + e,
          buttons: [
            {
              label: "Close",
              onClick: () => {},
            },
            {
              label: "Retry",
              onClick: () => {
                setExcelFile(undefined);
                showView();
              },
            },
          ],
        });
      });
  };
  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = CSVTemplate; // Provide the path to your local CSV file
    downloadLink.download = "NewPatientTemplete.csv"; // Specify the filename users will see
    downloadLink.click();
  };
  return (
    <Form>
      <Form.Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10,
        }}
      >
        <Form.Group
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Form.Label>Upload Multiple Patients</Form.Label>
          <Form.File
            style={{
              borderRadius: 6,
              borderColor: "#000",
              borderWidth: 1,
              padding: 6,
            }}
            accept=".csv"
            placeholder="upload excel"
            onChange={(e: any) => {
              if (e.target.files.length > 0) setExcelFile(e.target.files[0]);
            }}
          />
        </Form.Group>

        <div
          style={{
            marginTop: -15,
            padding: 8,
            borderRadius: 8,
            borderWidth: 1,
            backgroundColor: "#4ade80",
            cursor: "pointer",
          }}
          onClick={handleDownload}
        >
          {/* Download template */}

          <h1
            style={{
              color: "#000",
              fontSize: 13,
            }}
          >
            <i
              //@ts-ignore
              class="fa fa-download"
              aria-hidden="true"
              style={{ marginRight: 4 }}
            ></i>
            Download template
          </h1>
        </div>
        <Form.Group
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Button
            className="bg-[#5a6268]"
            variant="secondary"
            onClick={() => {
              setExcelFile(undefined);
              closeView();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="bg-[#0069d9]"
            onClick={() => fileUpload(accessToken)}
          >
            {ShowLoader && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="1x"
                color="white"
                style={{ marginRight: 10 }}
              />
            )}
            Upload
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
