import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Requested from './requested';
import Rejected from './rejected/rejected';
import Initiated from './initiated';
import Completed from './completed/completed';
import Approved from './approved/approved';
import { Content } from "antd/lib/layout/layout";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `sanitization-tab-${index}`,
    "aria-controls": `sanitization-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#000',
    flex: 1,
  },
}));

export const Sanitization = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <h4 className="body_wrapper" style={{ color: "#fff", marginLeft: 16 }}>
        Sanitization
      </h4>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="Sanitization"
        >
          {/* <Tab label="Requested" {...a11yProps(0)} />
          <Tab label="Initiated" {...a11yProps(1)} /> */}
          <Tab label="Approved" {...a11yProps(0)} />
          <Tab label="Completed" {...a11yProps(1)} />
          <Tab label="Rejected" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Requested />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}><Initiated /></Content>
        </TabPanel> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}><Approved /></Content>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}><Completed /></Content>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}><Rejected /></Content>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
