import React, { Component } from "react";
import { Card, CardDeck } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import "./pdf.css";

function Cards(props: any) {
  return (
    <>
      {/* <div className='dates'>
        <div className='start_date' style={{ textAlign:'center', color: 'green', marginLeft: '20%', fontWeight: 'bold' }}>
           Date : 10-05-2021
    </div>
       
      </div><br />
      */}

      <div className="Header_deisolation">
        <img className="logo_deisolation" src="/logo.png" alt="" />
        <div className="Title_deisolation">
          <p style={{ marginTop: "40px", fontSize: "30px" }}>
            NEWTOWN KOLKATA DEVELOPMENT AUTHORITY
          </p>
        </div>
        <img className="logo2_deisolation" src="/logo2.png" alt="" />
      </div>
      <div className="Header2_deisolation">
        <div className="Date_deisolation">
          <p style={{ fontWeight: "bold" }}>DATE : {moment().format("LL")}</p>
        </div>
        <div className="Title2_deisolation">
          <p>Sanitization Approved Data Sheet</p>
        </div>

        <div className="Time_deisolation">
          <p style={{ fontWeight: "bold" }}>TIME : {moment().format("LT")}</p>
        </div>
      </div>
      <div
        className="total"
        style={{
          color: "blue",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 30,
          marginTop: "60px",
        }}
      >
        Total Number Of Patients: {props.data.size}
      </div>
      <div>
        {props.data ? (
          <div>
            <table className="table">
              <thead>
                <tr style={{ fontWeight: "bold", fontSize: "larger" }}>
                  {/* <th>Id</th> */}

                  <th>Request ID</th>

                  <th>Patient Name</th>
                  <th>Sex</th>
                  <th>Age</th>
                  <th>Case Id</th>
                  <th>Status</th>
                  <th>Last Onlined</th>
                  <th>Approved Date</th>
                </tr>
              </thead>
              <tbody>
                {props.data.data.map((data: any, i: number) => {
                  var status = data[props.data.tableHeader[5]];

                  return (
                    <tr
                      style={{
                        color:
                          status === "ACTIVE"
                            ? "#999900"
                            : status === "CURED"
                            ? "green"
                            : "red",
                      }}
                    >
                      {/* <td>{data.id}</td> */}
                      <td>{data[props.data.tableHeader[0]]} </td>
                      <td> {data[props.data.tableHeader[1]]}</td>
                      <td> {data[props.data.tableHeader[2]]}</td>
                      <td> {data[props.data.tableHeader[3]]}</td>
                      <td> {data[props.data.tableHeader[4]]}</td>
                      <td> {data[props.data.tableHeader[5]]}</td>
                      <td> {moment(data[props.data.tableHeader[6]]).format('LL')}</td>
                      <td> {moment(data[props.data.tableHeader[7]]).format('LL')}</td>
                      {/* <td> {data[props.data.tableHeader[8]]}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p>Please Wait</p>
        )}
      </div>
    </>
  );
}
class PdfReport extends Component {
  render() {
    return (
      <div>
        <Cards data={this.props} />
      </div>
    );
  }
}

export default PdfReport;
