import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState, useAppDispatch } from "../../app/store";
import { loginByUsernamePassword } from "../../slices";
//import { useHistory } from 'react-router'
//import  form  from 'bootstrap'
import "./login.css";
export const Login = () => {
  //const history = useHistory
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [flag2,setFlag2] = useState(false)

  const user = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const handleEmailChange = (e: any) => {
    setEmailInput(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPasswordInput(e.target.value);
  };

  const handleLoginSubmit = async () => {
    await dispatch(
      loginByUsernamePassword({ username: emailInput, password: passwordInput })
    );
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLoginSubmit();
    }
  };

//   useEffect(() => {

//     if(document.referrer==='http://localhost:3000/'){
//       setFlag2(true)
//     }

//     console.log(document.referrer)
    
// }, [])

  if (user.loggedIn) return <Redirect to="/" />;
  return (
    <>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <img
              src="/user-profile.png"
              id="icon"
              alt="User Icon"
              style={{ margin: 10 }}
            />
          </div>
          <div onKeyPress={handleKeyPress}>
            <input
              type="text"
              id="email"
              className="fadeIn second"
              name="Email"
              placeholder="Email"
              value={emailInput}
              onChange={handleEmailChange}
            />
            <input
              type="password"
              id="password"
              className="fadeIn third"
              name="Password"
              placeholder="Password"
              value={passwordInput}
              onChange={handlePasswordChange}
            />
            <input
              type="submit"
              className="fadeIn fourth"
              value="Log In"
              onClick={() => handleLoginSubmit()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
