import React from "react";
import { Card, CardDeck } from "react-bootstrap";
import moment from "moment";
import { Layout } from "antd";
import "./pdf.css";
function PdfCard(props: any) {
  const { Header } = Layout;

  var sanitizedDate: any = moment(props?.data[0]?.updatedAt).format(
    "dddd Do MMMM YYYY"
  );
  var sanitizedTime: any = moment(props?.data[0]?.updatedAt).format("H:mm:ss");
  console.log(props.data[1]);
  return (
    <>
      {(props.index === 0 || props.index % 2 === 0) && (
        <>
          {/* <Header
            className="site-layout-background"
            style={{ padding: 0,backgroundColor:'#fff'}}
          > */}
          {/* <div
              className="Heading"
              style={{ color: "#000", fontWeight: "bold", paddingLeft: "10px" ,textAlign:'center'}}
            >
             NKDA SANITIZATION COMPLETED REPORT
              
              <img src='/logo.png' alt=''style={{ width: "70px",
                height: "50px",float:'right',marginRight:'20%',marginTop:'8px'}}/>
                 <img src='/logo2.png' alt=''style={{ width: "50px",
                height: "50px",float:'left',marginLeft:'20%',marginTop:'8px'}}/>
            </div> */}
          <div className="Header" style={{ height: "120px" }}>
            <img className="logo_contact" src="/logo.png" alt="" />
            <div className="Title">
              <p style={{ marginTop: "1px", fontSize: "20px" }}>
                NEWTOWN KOLKATA DEVELOPMENT AUTHORITY
              </p>
            </div>
            <img
              className="logo2_contact"
              src="/logo2.png"
              alt=""
              style={{ height: "90px" }}
            />
          </div>
          <div className="Header2">
            <div className="Date">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                DATE : {props.startDate} - {props.endDate}
              </p>
            </div>
            <div className="Title2">
              <p style={{ fontSize: "16px", whiteSpace: "nowrap" }}>
                SANITIZATION COMPLETED REPORT
              </p>
            </div>

            <div className="Time">
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                Total :{props.total}
              </p>
            </div>
          </div>

          {/* </Header> */}
          {/* <div className='dates'>
     <div className='start_date' style={{ float: 'left', color: 'green', marginLeft: '20%', fontWeight: 'bold' }}>
       Start Date :{props.startDate}
 </div>
     <div className='end_date' style={{ float: 'right', color: 'red', marginRight: '20%', fontWeight: 'bold' }}>
       End Date :{props.endDate}
 </div>
   </div><br />
  
   <div className='total' style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
     Total Locations Sanitized : {props.total}
 </div> */}
        </>
      )}

      <div
        style={{
          display: "content",
          border: "1px solid lightgrey",
          padding: "5px",
          margin: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderColor: "black",
            width: "100%",
            height: "345px",
          }}
        >
          <div>
            <div>
              <table
                className="pdfTable"
                style={{ width: "100%", marginTop: "10px" }}
              >
                <thead className="pdfHead">
                  <tr className="pdfTr">
                    <th
                      style={{
                        textAlign: "center",
                        backgroundColor: "lightgrey",
                        color: "orange",
                        fontSize: "16px",
                      }}
                    >
                      FIELD
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        backgroundColor: "lightgrey",
                        color: "orange",
                        fontSize: "16px",
                      }}
                    >
                      VALUE
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        backgroundColor: "lightgrey",
                        color: "orange",
                        fontSize: "16px",
                      }}
                    >
                      FIELD
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        backgroundColor: "lightgrey",
                        color: "orange",
                        fontSize: "16px",
                      }}
                    >
                      VALUE
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  <>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Patient Name :</td>
                      <td>{props?.data[0]?.name}</td>
                      <td style={{ fontWeight: "bold" }}>Phone Number :</td>
                      <td>{props?.data[0]?.mob_no}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Age :</td>
                      <td>{props?.data[0]?.age}</td>
                      <td style={{ fontWeight: "bold" }}>Sex :</td>
                      <td>{props?.data[0]?.sex}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Address :</td>
                      <td>{props?.data[0]?.address}</td>
                      <td style={{ fontWeight: "bold" }}>Action Area :</td>
                      <td>{props?.data[0]?.action_area}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Building :</td>
                      <td>{props?.data[0]?.building}</td>
                      <td style={{ fontWeight: "bold" }}>Plot :</td>
                      <td>{props?.data[0]?.plot_no}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Landmark :</td>
                      <td>{props?.data[0]?.landmark}</td>
                      <td style={{ fontWeight: "bold" }}>Sanitized Date :</td>
                      <td>{sanitizedDate}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Sanitized Time :</td>
                      <td>{sanitizedTime}</td>
                      <td style={{ fontWeight: "bold" }}>Status :</td>
                      <td>{props?.data[0]?.status}</td>
                    </tr>
                  </>
                </tbody>
              </table>
            </div>

            <div
              className="image-wraper"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "10px",
              }}
            >
              {props.data[1].map((url: string, index: number) => {
                if (index <= 3)
                  return (
                    <div
                      style={{
                        fontSize: 30,
                        textAlign: "center",
                        color: "#fff",
                        display: "inline",
                      }}
                    >
                      <img
                        src={url}
                        alt="image1"
                        style={{
                          marginTop: "10px",
                          display: "inline",
                          height: "138px",
                          width: "230px",
                        }}
                      />
                    </div>
                  );
              })}

              {/* <Card.Text style={{fontSize:30, textAlign:'center',color:'#fff',display:'inline'}}>
        <img src={props?.data[1][1]} alt='image2'  width='25%' style={{marginTop:'50px',display:'inline',paddingLeft:'30px'}} />
       
      </Card.Text>
      <Card.Text style={{fontSize:30, textAlign:'center',color:'#fff',display:'inline'}}>
        <img src={props?.data[1][2]} alt='image3'  width='25%' style={{marginTop:'50px',display:'inline',paddingLeft:'30px'}} />
       
      </Card.Text>
      <Card.Text style={{fontSize:30, textAlign:'center',color:'#fff',display:'inline'}}>
        <img src={props?.data[1][3]} alt='image4'  width='25%' style={{marginTop:'50px',display:'inline',paddingLeft:'30px'}}/>
       
      </Card.Text> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfCard;
