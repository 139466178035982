import {AxiosRequestConfig} from 'axios';
import { templateAPI } from './templateAPI';

export const LoginWithJWTApi = async (username: string, password: string) => {
    var user = JSON.stringify({
        "username": username,
        "password": password
      });
        const conf: AxiosRequestConfig = {
            method: 'post',
            url: 'https://api.cca.distronix.in:3443/caller/login',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : user
        }
  const result = await templateAPI(conf);
  // console.log("Inside LoginWithJWTApi: ", result.data);
      return result.data;
    }
  
