import { AxiosRequestConfig } from "axios";
import { templateAPI } from "./templateAPI";

export const GetSanitizationStatus = (startDate: string, endDate: string) => {

    
    var dateRange = JSON.stringify({
        "start_date": startDate,
        "end_date": endDate
    });
  
  const conf: AxiosRequestConfig = {
    method: "post",
    url: "https://api.cca.distronix.in:3443/executive/sanitizationCompleted2",
    headers: {
        'Content-Type': 'application/json'
    },
    data: dateRange

  };

  const result = templateAPI(conf);
  //
  return result;
};
