import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logger } from 'redux-logger';
import { userSlice } from '../slices'
import { pdfdataSlice } from '../slices/pdfData';


const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userSlice.reducer,
    pdfdata: pdfdataSlice.reducer,
  })
);


// export const store = configureStore({
//   reducer: {
//     user: userSlice.reducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware()
//       //   .prepend(
//       //     // correctly typed middlewares can just be used
//       //     additionalMiddleware,
//       //     // you can also type middlewares manually
//       //     untypedMiddleware as Middleware<
//       //       (action: Action<'specialAction'>) => number,
//       //       RootState
//       //     >,
//       //   )
//       // prepend and concat calls can be chained
//       .concat(logger),
// });

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() 