import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid, } from '@material-ui/data-grid';
import PdfReport from './DataGrid7';
import { Layout } from "antd";
//import './pdf.css'
//import patientData from '../'
const Resume = React.forwardRef((props, ref) => {
  const { Header, Content, Footer } = Layout;

  return (
    <>
      <div className=" p-6" ref={ref}>
        <Layout className="site-layout" >



          <Content style={{ margin: "0 16px", backgroundColor: 'white' }}>

            <PdfReport data={props.data} size={props.size} tableHeader={props.tableHeader} />

          </Content>


        </Layout>


      </div>
    </>
  );
});

export default Resume;
