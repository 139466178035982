import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
//import LaptopMacIcon from '@material-ui/icons/LaptopMac';
//import HotelIcon from '@material-ui/icons/Hotel';
//import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
//import { Button } from '@material-ui/core';
import "./timeline.css";

import moment from "moment";
import TypographyData from "./TypographyData";
import { PermIdentityOutlined } from "@material-ui/icons";
//import {faThermometer} from '@fortawesome/free-solid-svg-icons'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    width: "300px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function CustomizedTimeline(props: any) {
  // const {createdAt}=props.data

  // console.log(props.data);
  const [show, setShow] = useState(false);
  const handleShow1 = () => setShow(true);
  const handleClose1 = () => setShow(false);

  const classes = useStyles();

  // const handleShow2 = () => setShow(true);
  // const handleClose2 = () => setShow(false);
  // const handleShow3 = () => setShow(true);
  // const handleClose3 = () => setShow(false);

  var color = "green";

  if (
    props.data[0].ans["health"] === "unwell" &&
    props.data[0].ans["breathing_issues"] === "yes"
  ) {
    color = "red";
  }
  if (
    props.data[0].ans["health"] === "unwell" &&
    props.data[0].ans["breathing_issues"] === "No" &&
    props.data[0].ans["fever"] === "yes"
  ) {
    color = "yellow";
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="h6" component="h1">
          {moment(props.data[0].createdAt).format("L")}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot variant="outlined">
          <PermIdentityIcon style={{ color: `${color}`, fontSize: 30 }} />
          {/* <AccountCircleIcon style={{ color: `${color}`, fontSize: 40 }} /> */}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1" style={{ fontSize: "16px" }}>
            {moment(props.data[0].createdAt).format("LT")}
          </Typography>
          <Typography>
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>Health : </p>
                <p style={{ color: "red" }}>{props.data[0].ans["health"]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>Fever : </p>
                <p style={{ color: "red" }}>{props.data[0].ans["fever"]}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Breathing Problem:
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["breathing_issues"]}
                </p>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Consuming Paracetamol :
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["consuming_paracetamol"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Measuring O2 Regularly:
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["measuring_o2_regularly"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Measuring O2 Frequency:
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["measuring_o2_regularly"]}
                </p>
              </div>
             
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Breathing Issues :
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["breathing_issues"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Food Delivery :
                </p>
                <p style={{ color: "red" }}>
                  {props.data[0].ans["food_delivery"]}
                </p>
              </div> */}
            </>
          </Typography>

          {!!show && <ExpandLessIcon onClick={handleClose1} />}
          {show ? (
            props.data.map((tdata: any, index: any) => (
              <TypographyData
                tdata={tdata}
                handleClose1={handleClose1}
                index={index}
              />
            ))
          ) : (
            <ExpandMoreIcon onClick={handleShow1} />
          )}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
}
