import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Isolation from "./isolation/isolation";
import Deisolation from "./deisolation/deisolation";
import Contact from "./contact/contact";
import Nonnkda from "./nonnkda/nonnkda";
import Critical from "./critical/critical";
import Modified from "./modified/modified";
import Feedback from "./FeedbackPatient/Feedback";
import { Content } from "antd/lib/layout/layout";


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `sanitization-tab-${index}`,
    "aria-controls": `sanitization-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#000",
    flex: 1,
  },
}));

export const Reports = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <h4 className="body_wrapper" style={{ color: "#fff", marginLeft: 16 }}>
        Reports
      </h4>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="Reports"
        >
          {/* <Tab label="Requested" {...a11yProps(0)} />
          <Tab label="Initiated" {...a11yProps(1)} /> */}
          <Tab label="Maleria" {...a11yProps(0)} style={{backgroundColor:value===0 ?'#fff':'lightgray'}}/>
          <Tab label="Dengue" {...a11yProps(1)} style={{backgroundColor:value===1 ?'#fff':'lightgray'}}/>
          <Tab label="Polio" {...a11yProps(4)} style={{backgroundColor:value===4 ?'#fff':'lightgray'}}/>
          <Tab label="Typhoid" {...a11yProps(2)} style={{backgroundColor:value===2 ?'#fff':'lightgray'}}/>
          <Tab label="Others" {...a11yProps(3)} style={{backgroundColor:value===3 ?'#fff':'lightgray'}}/>
          {/* <Tab label="Modified List" {...a11yProps(5)} style={{backgroundColor:value===5 ?'#fff':'lightgray'}}/> */}
          {/* <Tab label="Feedback Given List" {...a11yProps(6)} style={{backgroundColor:value===6 ?'#fff':'lightgray'}}/> */}
        </Tabs>
      </AppBar>
      <div style={{ width: '400px', height: '50px', padding: '3px', backgroundColor: '#ff9f9f', color: '#333', position: 'absolute', right: '4.1%', top: '20%', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        Total Major Disease Cases : &nbsp;<span style={{color: '#000', fontSize: '28px'}}>7678</span>
      </div>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Requested />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}><Initiated /></Content>
        </TabPanel> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Isolation />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Deisolation />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Contact />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Nonnkda />
          </Content>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Critical />
          </Content>
        </TabPanel>

        <TabPanel value={value} index={5} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Modified />
          </Content>
        </TabPanel>
        
        <TabPanel value={value} index={6} dir={theme.direction}>
          <Content style={{ margin: "0 16px" }}>
            <Feedback />
          </Content>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};
