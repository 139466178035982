import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid, } from '@material-ui/data-grid';
import PdfReport from './DataGrid7';
import { Layout } from "antd";
import './pdf.css'
const Resume = React.forwardRef((props, ref) => {

  const { Header, Content } = Layout;
  return (
    <>
      <div className=" p-6" ref={ref}>
        <Layout className="site-layout" >
          {/* <Header
            className="site-layout-background"
            style={{ padding: 0,backgroundColor:'#fff'}}
          >
            <div
              className="Heading"
              style={{ color: "#000", fontWeight: "bold", paddingLeft: "10px" ,textAlign:'center'}}
            >
             NKDA SANITIZATION COMPLETED REPORT
              
              <img src='logo.png' alt=''style={{ width: "70px",
                height: "50px",float:'right',marginRight:'20%'}}/>
                 <img src='logo2.png' alt=''style={{ width: "50px",
                height: "50px",float:'left',marginLeft:'20%'}}/>
            </div>
          </Header> */}

          <Content style={{ margin: "0 16px", backgroundColor: 'white' }}>

            <PdfReport />

          </Content>


        </Layout>


      </div>
    </>
  );
});

export default Resume;
