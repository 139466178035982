import {
  faCheckCircle,
  faCircleNotch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { addSanitizationRequest } from "../../api/addSanitizationRequest";
import {
  addSinglePatient,
  ISinglePatientFormData,
} from "../../api/addSinglePatient";
import { RootState } from "../../app/store";
import { useSnackbar } from 'notistack';
import { Formik, Form as FormikForm } from "formik";
import AchieveGrid from "../AchieveGrid";

export const AddNewPatientForm: React.FC<{ closeView: () => void }> = ({
  closeView = () => { },
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const initialState = "";
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [Name, setName] = useState(initialState);
  const [Phone, setPhone] = useState(initialState);
  const [FullAddress, setFullAddress] = useState(initialState);
  const [Flat, setFlat] = useState(initialState);
  const [Plot, setPlot] = useState(initialState);
  const [Building, setBuilding] = useState(initialState);
  const [Street, setStreet] = useState(initialState);
  const [Landmark, setLandmark] = useState(initialState);
  const [ActionArea, setActionArea] = useState(initialState);
  const [Email, setEmail] = useState(initialState);
  const [Age, setAge] = useState<number | null>(null);
  const [Sex, setSex] = useState(initialState);
  const [Vaccinated, setVaccinated] = useState(false);
  const [QuarantineStatus, setQuarantineStatus] = useState("");
  const [CurrentStatusCategory, setCurrentStatusCategory] = useState("ACTIVE");
  const [DiebetesStatus, setDiebetesStatus] = useState(initialState);
  const [IsolationStatus, setIsolationStatus] = useState(initialState);
  const [CollectionDate, setCollectionDate] = useState(initialState);
  const [AdmissionDate, setAdmissionDate] = useState(initialState);
  const [PlaceOfAdmission, setPlaceOfAdmission] = useState(initialState);
  const [StatusOfPatient, setStatusOfPatient] = useState(initialState);
  const [CovidReportDate, setCovidReportDate] = useState(initialState);
  const [statusOfDirectContact, setStatusOfDirectContact] = useState(initialState);
  const [resultOfDirectContact, setResultOfDirectContact] = useState(initialState)
  const [statusOfIndirectContact, setStatusOfIndirectContact] = useState(initialState);
  const [HospitalName, setHospitalName] = useState(initialState);
  const [diseaseList, setDiseaseList] = useState<any>([]);
  const [DirectContacts, setDirectContacts] = useState<
    { name: string; relation: string }[]
  >([{ name: "", relation: "" }]);
  const [IndirectContacts, setIndirectContacts] = useState<
    { name: string; relation: string }[]
  >([{ name: "", relation: "" }]);
  const [SanitizationRequest, setSanitizationRequest] = useState(false);
  const updateDirectContacts = (
    { name, relation }: { name?: string; relation?: string },
    id: number
  ) => {
    if ((!name || name === "") && (!relation || relation === "")) {
      setDirectContacts((s) => s.filter((item, index) => index !== id));
      return;
    }
    const d: { name: string; relation: string } = {
      name: !!name ? name : DirectContacts[id].name,
      relation: !!relation ? relation : DirectContacts[id].relation,
    };
    setDirectContacts((s) => s.map((i, index) => (id === index ? d : i)));
  };
  const updateIndirectContacts = (
    { name, relation }: { name?: string; relation?: string },
    id: number
  ) => {
    if ((!name || name === "") && (!relation || relation === "")) {
      setIndirectContacts((s) => s.filter((item, index) => index !== id));
      return;
    }
    const d: { name: string; relation: string } = {
      name: !!name ? name : IndirectContacts[id].name,
      relation: !!relation ? relation : IndirectContacts[id].relation,
    };
    setIndirectContacts((s) => s.map((i, index) => (id === index ? d : i)));
  };

  const [Touched, setTouched] =
    useState<{
      name: boolean;
      phone: boolean;
      address: boolean;
      collectionDate: boolean;
    }>({
      name: false,
      phone: false,
      address: false,
      collectionDate: false,
    });

  const [IsError, setIsError] = useState<boolean>(false);

  const validateBeforeSubmit = () => {
    let err: string[] = [];
    let isAnyErr = false;

    if (PlaceOfAdmission === "") {
      enqueueSnackbar("Please fill the place of admission", { variant: 'error' });
      // err.push("Please fill the patient's name.");
      isAnyErr = true;
    }


    if (Name === "") {
      enqueueSnackbar("Please fill the patient's name.", { variant: 'error' });
      // err.push("Please fill the patient's name.");
      isAnyErr = true;
    }
    if (FullAddress === "") {
      enqueueSnackbar("Please fill the patient's address.", {
        variant: "error",
      });
      isAnyErr = true;
    }
    if (Phone.length !== 10) {
      enqueueSnackbar(
        "Please fill the patient's valid 10 digit phone number.",
        { variant: "error" }
      );
      isAnyErr = true;
    }
    if (CollectionDate === "") {
      enqueueSnackbar("Please fill the patient's sample collection date.", {
        variant: "error",
      });
      isAnyErr = true;
    }
    console.log("Errors: ", err.join(' \n '))
    setIsError(isAnyErr);
    return isAnyErr;
  };

  const addDirectContacts = (
    { name, relation }: { name?: string; relation?: string },
    id: number
  ) => {
    updateDirectContacts({ name, relation }, id);
    setDirectContacts((s) => [...s, { name: "", relation: "" }]);
  };
  const addIndirectContacts = (
    { name, relation }: { name?: string; relation?: string },
    id: number
  ) => {
    updateIndirectContacts({ name, relation }, id);
    setIndirectContacts((s) => [...s, { name: "", relation: "" }]);
  };

  const clearForm = () => {
    setActionArea("");
    setAge(null);
    setBuilding("");
    setCollectionDate("");
    setCurrentStatusCategory("");
    setDiebetesStatus("");
    setDirectContacts([{ name: "", relation: "" }]);
    setIndirectContacts([{ name: "", relation: "" }]);
    setEmail("");
    setFlat("");
    setFullAddress("");
    setIsolationStatus("");
    setLandmark("");
    setName("");
    setPhone("");
    setPlaceOfAdmission("");
    setPlot("");
    setQuarantineStatus("");
    setSex("");
    setStatusOfPatient("");
    setStreet("");
    setVaccinated(false);
    setStatusOfDirectContact('')
  };

  const insertData = async () => {
    const validated = validateBeforeSubmit();
    if (validated) {
      // const errToShow = Errors.join(" \n ");
      // alert(errToShow)
      // console.log(errToShow)
      return;
      // alert();
    }
    const data: ISinglePatientFormData = {
      id: "",
      action_area: ActionArea,
      address: FullAddress,
      age: !Age ? null : Age,
      building: Building,
      diabetes_report: DiebetesStatus,
      direct_contuct: DirectContacts.slice(0, -1)
        .map((d) => d.name + ": " + d.relation)
        .join(", "),
      email: Email,
      flat_no: Flat,
      group_id: null,
      hospital_name: HospitalName,
      hospital_admition_date: AdmissionDate === "" ? null : AdmissionDate,
      indirect_contact: IndirectContacts.slice(0, -1)
        .map((d) => d.name + ": " + d.relation)
        .join(", "),
      // is_active: CurrentStatusCategory === "ACTIVE",
      isolation_status: IsolationStatus,
      is_vaccinated: Vaccinated,
      landmark: Landmark,
      mob_no: Phone,
      name: Name,
      no_of_direct_contact: DirectContacts.length - 1,
      place_of_admition: PlaceOfAdmission,
      plot_no: Plot,
      reporting_covid_date: CovidReportDate === "" ? null : CovidReportDate,
      result_of_direct_contact: resultOfDirectContact,
      sample_collection_date: CollectionDate,
      sex: Sex,
      status: CurrentStatusCategory,
      status_of_direct_contact: statusOfDirectContact,
      status_of_indirect_contact: statusOfIndirectContact,
      status_of_patient: StatusOfPatient,
      street_no: Street,
    };
    console.log('before');
    const result = await addSinglePatient(data, accessToken)
      .then((r) => {
        alert(!r.error ? "Data Inserted Successfully" : r.error);
        if (IsError) clearForm();
        console.log(r);
        return r;
      })
      .catch((e) => {
        alert("Data Not Inserted. " + e);
        console.log(e);
        return e;
      });
    console.log("Patient case id", result.data["case_id"]);
    if (SanitizationRequest) {
      const id = result.data.case_id;
      if (typeof id === "number") {
        await addSanitizationRequest(id, accessToken)
          .then((r) => {
            if (r.status === 200)
              enqueueSnackbar("Sanitization Request Added Successfully", {
                variant: "success",
              });
            else {
              enqueueSnackbar("Sanitization Request could not be added.", {
                variant: "error",
              });
            }
          })
          .catch((e) => {
            enqueueSnackbar(
              `Sanitization Request could not be added due to "${e.response.data.Message}"`,
              {
                variant: "error",
              }
            );
          });
      }
    }
  };
  const closeFormAfterInsertion = async () => {
    await insertData();
    closeView();
  };
  const diseaseHandler = (data: any) => {
    setDiseaseList(data.DiseaseList.map((item:any,i:any)=> ({
      disease_name: data[`disease${i+1}`],
      vaccination: data[`vaccination${i+1}`],
      date: data[`date${i+1}`],
    })))
  }
  console.log(diseaseList)

  return (
    <Formik
    initialValues={{}}
    onSubmit={()=> {
      // console.log(values);
    }}
    >
      {({
            //@ts-ignore
            errors,
            values,
            //@ts-ignore
            touched,
          }) => (
    <Form>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>
            Patient Name<sup style={{ color: "red", marginRight: 20 }}>*</sup>
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>
            Patient Phone Number
            <sup style={{ color: "red", marginRight: 20 }}>*</sup>
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Phone}
            onChange={(e) => {
              const input = parseInt(e.target.value);
              setPhone(String(isNaN(input) ? "" : input));
            }}
          ></Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>
            Patient Full Address
            <sup style={{ color: "red", marginRight: 20 }}>*</sup>
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={FullAddress}
            onChange={(e) => {
              setFullAddress(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Flat Number</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Flat}
            onChange={(e) => setFlat(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Plot Number</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Plot}
            onChange={(e) => setPlot(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Building Name</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Building}
            onChange={(e) => setBuilding(e.target.value)}
          ></Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Street Number</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Street}
            onChange={(e) => setStreet(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Landmark</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Landmark}
            onChange={(e) => setLandmark(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Action Area</Form.Label>
          <Form.Control
            onChange={(e) => setActionArea(e.currentTarget.value)}
            as="select"
            value={ActionArea}
          >
            <option disabled value="">
              Please Select
            </option>
            <option value="AA-I">AA - I</option>
            <option value="AA-II">AA - II</option>
            <option value="AA-III">AA - III</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        {/* <Form.Group as={Col}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group> */}
        <Form.Group as={Col}>
          <Form.Label>Age</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={typeof Age === "number" ? Age : ""}
            onChange={(e) => {
              const newAge = parseInt(e.target.value);
              if (isNaN(newAge)) setAge(null);
              else setAge(newAge);
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Sex</Form.Label>
          <Form.Control
            onChange={(e) => setSex(e.currentTarget.value)}
            as="select"
            value={Sex}
          >
            <option disabled value="">
              Please Select
            </option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row style={{margin: 5,flex:1 }}>
      <Form.Group as={Col}>
          <FormikForm>
            {/* <div style={{padding: 4, margin: 4, width: '100%'}}> */}
              <AchieveGrid
                header = {["Serial No","Disease Name", "Vaccination Status", "Vaccination Date"]}
                names = {["slno","disease", "vaccination", "date"]}
                dropdown = {["disease", "vaccination"]}
                diseaseOptions = {["Malaria", "Dengue","Polio","Typhoid"]}
                options = {["Vaccinated", "Not Vaccinated"]}
                inputList = {[]}
                onChangeValues = {diseaseHandler}
                // values={values}
              />
            {/* </div> */}
          </FormikForm>
      </Form.Group>
        
        {/* <Form.Group as={Col}>
          <Form.Label>Vaccination Status</Form.Label>
          <Button
            style={{
              width: 180,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 5,
            }}
            variant={Vaccinated ? "success" : "light"}
            onClick={(e) => setVaccinated((s) => !s)}
          >
            <FontAwesomeIcon
              icon={Vaccinated ? faCheckCircle : faCircleNotch}
              size="lg"
              color={Vaccinated ? "white" : "green"}
            />
            <strong style={{ paddingLeft: 5 }}>
              {Vaccinated ? "Vaccinated" : "Not Vaccinated"}
            </strong>
          </Button>
        </Form.Group> */}
        {/* <Form.Group as={Col}>
          <Form.Label>Quarantine Status</Form.Label>
          <Form.Group>
            <ButtonGroup vertical>
              <Button
                variant={
                  QuarantineStatus === "Home Quarantined" ? "primary" : "light"
                }
                onClick={(e) => setQuarantineStatus("Home Quarantined")}
              >
                Home Quarantined
              </Button>
              <Button
                variant={
                  QuarantineStatus === "Hospitalized" ? "primary" : "light"
                }
                onClick={(e) => setQuarantineStatus("Hospitalized")}
              >
                Hospitalized
              </Button>
            </ButtonGroup>
          </Form.Group>
        </Form.Group> */}
        {/* <Form.Group as={Col}>
          <Form.Label>Current Status Category</Form.Label>
          <Form.Control
            onChange={(e) => setCurrentStatusCategory(e.currentTarget.value)}
            as="select"
            value={CurrentStatusCategory}
            disabled
          >
            <option disabled value="">
              Please Select
            </option>
            <option value="ACTIVE">Active</option>
            <option value="CURED">Cured</option>
            <option value="EXPIRED">Expired</option>
          </Form.Control>
        </Form.Group> */}
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Place of Admission (Hospital)<sup style={{ color: "red", marginRight: 20 }}>*</sup></Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            as="select"
            value={PlaceOfAdmission}
            onChange={(e) => setPlaceOfAdmission(e.target.value)}
          >

            <option disabled value="">
              Please Select
            </option>
            <option>Home Isolation</option>
            <option>Hospitalized</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>
            Hospital Admission Date
            {/* <Button
              variant="outline-primary"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setAdmissionDate(moment().format("YYYY-MM-DD"));
              }}
            >
              Set Today
            </Button> */}
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="date"
            value={AdmissionDate}
            onChange={(e) => {
              console.log(e.target.value);
              setAdmissionDate(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Hospital Name</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={HospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label style={{ marginRight: 20 }}>Diabetic?</Form.Label>
          <ButtonGroup>
            <Button
              variant={DiebetesStatus === "Yes" ? "primary" : "light"}
              onClick={(e) => setDiebetesStatus("Yes")}
            >
              Yes
            </Button>
            <Button
              variant={DiebetesStatus === "No" ? "primary" : "light"}
              onClick={(e) => setDiebetesStatus("No")}
            >
              No
            </Button>
          </ButtonGroup>
        </Form.Group>
        {/* <Form.Group as={Col}>
          <Form.Label>Isolation Status</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={IsolationStatus}
            onChange={(e) => setIsolationStatus(e.target.value)}
          ></Form.Control>
        </Form.Group> */}
      </Form.Row>
      {/* <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Status of Patient</Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="text"
            value={StatusOfPatient}
            onChange={(e) => setStatusOfPatient(e.target.value)}
          ></Form.Control>
        </Form.Group>
      </Form.Row> */}
      {/* <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>
            Sample Collection Date
            <sup style={{ color: "red", marginRight: 20 }}>*</sup>
            <Button
              variant="outline-primary"
              onClick={() => {
                setCollectionDate(moment().format("YYYY-MM-DD"));
              }}
            >
              Set Today
            </Button>
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="date"
            value={CollectionDate}
            onChange={(e) => {
              console.log(e.target.value);
              setCollectionDate(moment(e.target.value).format('YYYY-MM-DD'));
            }}
          ></Form.Control>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>
            Covid Reporting Date
            <Button
              variant="outline-primary"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setCovidReportDate(moment().format("YYYY-MM-DD"));
              }}
            >
              Set Today
            </Button>
          </Form.Label>
          <Form.Control
            style={{ borderWidth: 1, borderColor: "lightgrey" }}
            type="date"
            value={CovidReportDate}
            onChange={(e) => {
              console.log(e.target.value);
              setCovidReportDate(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
      </Form.Row> */}
      {/* <Form.Label>Direct Contacts</Form.Label>
      {DirectContacts.map((d, i) => {
        return d.name === "" && d.relation === "" ? (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Name"
                  value={d.name}
                  onChange={(e) =>
                    addDirectContacts({ name: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Relation"
                  value={d.relation}
                  onChange={(e) =>
                    addDirectContacts({ relation: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
             <Form.Group as={Col}>
             <Form.Label>Status of Direct Patients</Form.Label>
            <Form.Control
            onChange={(e) => setStatusOfDirectContact(e.currentTarget.value)}
            as="select"
            value={statusOfDirectContact}
            >
            <option disabled value="">
              Please Select
            </option>
            <option value="unknown">Unknown</option>
            <option value="Hospitalized">Hospitalized</option>
            <option value="Home Quarantine">Home Quarantine</option>
          </Form.Control>
           </Form.Group>

           <Form.Group as={Col}>
          <Form.Label>Result of Direct Patients</Form.Label>
          <Form.Control
            onChange={(e) => setResultOfDirectContact(e.currentTarget.value)}
             type='text'
            value={resultOfDirectContact}
          >
            
          </Form.Control>
        </Form.Group>

             </Form.Row>
          </>
        ) : (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Name"
                  value={d.name}
                  onChange={(e) =>
                    updateDirectContacts({ name: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Relation"
                  value={d.relation}
                  onChange={(e) =>
                    updateDirectContacts({ relation: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
            </Form.Row>
          </>
        );
      })} */}
      {/* <p style={{ fontSize: 12, fontWeight: "lighter", color: "grey" }}>
        Type above to add more.
      </p> */}
      {/* <Form.Label>Indirect Contacts</Form.Label>
      {IndirectContacts.map((d, i) => {
        return d.name === "" && d.relation === "" ? (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Name"
                  value={d.name}
                  onChange={(e) =>
                    addIndirectContacts({ name: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Relation"
                  value={d.relation}
                  onChange={(e) =>
                    addIndirectContacts({ relation: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
            </Form.Row>

           <Form.Group as={Col}>
          <Form.Label>Status of Indirect Patients</Form.Label>
          <Form.Control
            onChange={(e) => setStatusOfIndirectContact(e.currentTarget.value)}
            as="select"
            value={statusOfIndirectContact}
          >
            <option disabled value="">
              Please Select
            </option>
            <option value="unknown">Unknown</option>
            <option value="Hospitalized">Hospitalized</option>
            <option value="Home Quarantine">Home Quarantine</option>
          </Form.Control>
        </Form.Group>
          </>
        ) : (
          <>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Name"
                  value={d.name}
                  onChange={(e) =>
                    updateIndirectContacts({ name: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  style={{ borderWidth: 1, borderColor: "lightgrey" }}
                  type="text"
                  placeholder="Relation"
                  value={d.relation}
                  onChange={(e) =>
                    updateIndirectContacts({ relation: e.target.value }, i)
                  }
                ></Form.Control>
              </Form.Group>
            </Form.Row>
          </>
        );
      })} */}
      {/* <p style={{ fontSize: 12, fontWeight: "lighter", color: "grey" }}>
        Type above to add more.
      </p> */}
      {/* <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Add Sanitization Request?</Form.Label>
          <Button
            style={{
              width: 180,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 30,
              padding: 5,
            }}
            variant={SanitizationRequest ? "success" : "danger"}
            onClick={(e) => setSanitizationRequest((s) => !s)}
          >
            <FontAwesomeIcon
              icon={SanitizationRequest ? faCheckCircle : faTimesCircle}
              size="lg"
              color={"white"}
            />
            <strong style={{ paddingLeft: 5 }}>
              {SanitizationRequest ? "Yes" : "No"}
            </strong>
          </Button>
        </Form.Group>
      </Form.Row> */}

      <Form.Row style={{ gap: 20, justifyContent: "flex-end" }}>
        <Button
          variant="secondary"
          onClick={() => {
            closeView();
          }}
          className="bg-[#5a6268]"
        >
          Close
        </Button>
        <Button
        className="bg-[#e0a800]"
          variant="warning"
          onClick={() => {
            clearForm();
          }}
        >
          Clear
        </Button>
        <Button variant="primary" className="bg-[#0069d9]" onClick={() => closeFormAfterInsertion()}>
          Save and Close
        </Button>
        <Button variant="success" className=" bg-[#218838]" onClick={() => insertData()}>
          Save and Add more{" "}
        </Button>
      </Form.Row>
    </Form>
    )}
    </Formik>
  );
};
