import { templateAPI } from "./templateAPI";
import axios, { AxiosRequestConfig } from "axios";

export const getContactData = async (dataType:string) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: "https://api.cca.distronix.in:3443/reports/contact_tracing?order_by=sample_collection_date&order=DESC&page_no=1&per_page=999",
    // headers: {
    //   authorization: key,
    // },
    params: {
      show: dataType
    }
  };

  const result = await axios(config)
    .then((res) => ({ data: res.data, success: true, error: "" }))
    .catch((e) => ({ error: e, success: false, data: undefined }));
  return result;
};