import React, { useState } from "react";
import styles from "./index.module.css";
import * as types from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faUserCog,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faPhoneAlt,
  faSprayCan,
  faUsers,
  faUserPlus,
  faTable,
  faInfoCircle,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
export const SideBar = (props: types.SideBarProps) => {
  const [ActiveButton, setActiveButton] = useState<
    | "calllist"
    | "patients"
    | "sanitization"
    | "deisolation"
    | "reports"
    | "bullatin"
    | "NewsFeed"
  >("calllist");
  const [SideBarState, setSideBarState] = useState<"COLLAPSED" | "EXPANDED">(
    "EXPANDED"
  );

  return (
    <div
      className={styles.SideBar}
      style={{ width: SideBarState === "EXPANDED" ? 240 : 60 }}
    >
      <div className={styles.SideBarToggleButton}>
        {SideBarState === "EXPANDED" ? (
          <Tooltip arrow placement="right" title="Collapse the SideBar">
            <FontAwesomeIcon
              size="lg"
              color="#999999"
              style={{ float: "right", cursor: "pointer" }}
              icon={faAngleDoubleLeft}
              onClick={() => setSideBarState("COLLAPSED")}
            />
          </Tooltip>
        ) : (
          <Tooltip arrow placement="right" title="Expand the Sidebar">
            <FontAwesomeIcon
              size="lg"
              color="#999999"
              style={{ float: "right", cursor: "pointer" }}
              icon={faAngleDoubleRight}
              onClick={() => setSideBarState("EXPANDED")}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.UserInfo}>
        <img
          style={
            SideBarState === "EXPANDED"
              ? { height: 64, width: 64, borderRadius: 32 }
              : { height: 40, width: 40, borderRadius: 20 }
          }
          className={styles.UserAvatar}
          src={props.UserAvatar}
          alt={props.UserName + " logo"}
        ></img>
        {SideBarState === "EXPANDED" && (
          <div className={styles.UserNameAndDesignation}>
            <div className={styles.UserName}>{props.UserName}</div>
            <div className={styles.UserDesignation}>
              {props.UserDesignation}
            </div>
          </div>
        )}
      </div>
      <div
        className={styles.AccountSettingsWrapper}
        onClick={() => props.AddNewPatientHandler((s) => !s)}
      >
        <Tooltip arrow placement="right" title="Add New Patients">
          {SideBarState === "EXPANDED" ? (
            <>
              <div className={styles.AccountSettingsButton}>
                <FontAwesomeIcon
                  size="1x"
                  className={styles.SideBarOptionIcon}
                  color="#CCCCCC"
                  icon={faUserPlus}
                />{" "}
                Add New Patients
              </div>
            </>
          ) : (
            <div className={styles.SideBarOption}>
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faUserPlus}
              />
            </div>
          )}
        </Tooltip>
      </div>
      {/* <Tooltip arrow placement="right" title="Call List">
        <Link to="/user/calllist" onClick={() => setActiveButton("calllist")}>
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "calllist" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faPhoneAlt}
              />
              <div className={styles.SideBarOptionTitle}>Call List</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "calllist" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faPhoneAlt}
              />
            </div>
          )}
        </Link>
      </Tooltip> */}

      <Tooltip arrow placement="right" title="Patient Master">
        <Link to="/user/patients" onClick={() => setActiveButton("patients")}>
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "patients" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faDatabase}
              />
              <div className={styles.SideBarOptionTitle}>Patients Master</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "patients" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faDatabase}
              />
            </div>
          )}
        </Link>
      </Tooltip>
      {/* <Tooltip arrow placement="right" title="Sanitization">
        <Link
          to="/user/sanitization"
          onClick={() => setActiveButton("sanitization")}
        >
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "sanitization"
                  ? styles.SideBarOptionActive
                  : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faSprayCan}
              />
              <div className={styles.SideBarOptionTitle}>Sanitization</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "sanitization"
                  ? styles.SideBarOptionActive
                  : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faSprayCan}
              />
            </div>
          )}
        </Link>
      </Tooltip> */}
      <Tooltip arrow placement="right" title="Reports">
        <Link to="/user/reports" onClick={() => setActiveButton("reports")}>
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "reports" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faTable}
              />
              <div className={styles.SideBarOptionTitle}>Reports</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "reports" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faTable}
              />
            </div>
          )}
        </Link>
      </Tooltip>

      <Tooltip arrow placement="right" title="Bulletin">
        <Link to="/user/bullatin" onClick={() => setActiveButton("bullatin")}>
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "bullatin" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faInfoCircle}
              />
              <div className={styles.SideBarOptionTitle}>Bulletin</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "bullatin" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faInfoCircle}
              />
            </div>
          )}
        </Link>
      </Tooltip>
      <Tooltip arrow placement="right" title="News Feed">
        <Link to="/user/NewsFeed" onClick={() => setActiveButton("NewsFeed")}>
          {SideBarState === "EXPANDED" ? (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "NewsFeed" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faNewspaper}
              />
              <div className={styles.SideBarOptionTitle}>News Feed</div>
            </div>
          ) : (
            <div
              className={`${styles.SideBarOption} ${
                ActiveButton === "NewsFeed" ? styles.SideBarOptionActive : ""
              }`}
            >
              <FontAwesomeIcon
                size="1x"
                className={styles.SideBarOptionIcon}
                color="#CCCCCC"
                icon={faNewspaper}
              />
            </div>
          )}
        </Link>
      </Tooltip>

      {/* <Tooltip arrow placement="right" title="Deisolation">
        {SideBarState === "EXPANDED" ? (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "deisolation" ? styles.SideBarOptionActive : ""
            }`}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faUsers}
            />
            <div className={styles.SideBarOptionTitle}>Deisolation</div>
          </div>
        ) : (
          <div
            className={`${styles.SideBarOption} ${
              ActiveButton === "deisolation" ? styles.SideBarOptionActive : ""
            }`}
          >
            <FontAwesomeIcon
              size="1x"
              className={styles.SideBarOptionIcon}
              color="#CCCCCC"
              icon={faUsers}
            />
          </div>
        )}
      </Tooltip> */}
    </div>
  );
};
