import { Content } from "antd/lib/layout/layout";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCallListApi, removePatientStatus } from "../../api";
import { RootState } from "../../app/store";
import Cards from "../../components/cards";
import { ISinglePatientData } from "../patients/DataGrid";
import {CallListDataGrid} from "./DataGrid";
import { Form, FormControl } from "react-bootstrap";
import moment from 'moment'
import DatewiseCards from "../../components/DatewiseCards";
export const CallCenter = () => {
  const [SearchQuery, setSearchQuery] = useState("");
  const { accessToken } = useSelector((state: RootState) => state.user);
  // const [PatientID, setPatientID] = useState("");
  const [rowData, setRowData] = useState<ISinglePatientData[]>([]);
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);
  const [pdfShow, setPdfShow] = useState<boolean>(false);
  // const [Answer, setAnswer] = useState<boolean | undefined>();
  // const [IDs, setIDs] = useState<{ case_id: number; new_status: string }[]>([]);
  // const FreeTextSearch = (q: string) => {
  //   if (q === "") {
  //   console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

  //     setFilteredData(rowData);
  //     return;
  //   }
  //   const searcher = new RegExp(q, "i");

  //   const data = rowData.filter(
  //     (i) => JSON.stringify(i).match(searcher) !== null
  //   );
  //   console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
  //   setFilteredData(data);
  //   return;
  // };
  const debouncedFilter = useCallback(
    () => debounce((q: string) => {
      if (q === "") {
    // console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

      setFilteredData(rowData);
      return;
    }
    const searcher = new RegExp(q, "i");

    const data = rowData.filter(
      (i) => JSON.stringify(i).match(searcher) !== null
    );
    // console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
    setFilteredData(data);
    return;
    }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };

const addMoment = (items:any)=>{
      
  const newItem =items?.map((item:any)=>{
     
    return{
      ...item,
      sample_collection_date: moment(item["sample_collection_date"]).format('YYYY-MM-DD')
    }
  })
  return newItem
}

  const LoadData = () => {
    GetCallListApi(accessToken).then((rows) => {

        const arr = addMoment(rows?.data)

        if (!!arr) {
            setRowData(arr);
            setFilteredData(arr);
        }
      console.log({ rows });
    });
  };
  const handlePDFShow = (input?:boolean) => {
    if(typeof input==='undefined'){
      return pdfShow

    }
    else{
      setPdfShow(input);
      return input
    }
   
  };
  // useEffect(() => {
  //   FreeTextSearch(SearchQuery);
  // }, [SearchQuery])
  useEffect(() => {
      LoadData();
      
  }, []);
  const callRemovePatientFromCallListAPI = async (s: number[]) => {
    const ids = s.filter((id) => !isNaN(id));
    await removePatientStatus(ids, accessToken);
    LoadData();
  };
  return (
    <Content style={{ margin: "12px 16px", flex: 1 }}>
      <h4 className="body_wrapper" style={{ color: "#fff", marginTop: "3px",textAlign:'center' }}>
        Call List
      </h4>
      <h5 className="body_wrapper" style={{color: "#fff" }}>
         Total
      </h5>
      <Cards />
      <DatewiseCards />
      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
      
        <FormControl
          style={{ width: 200, marginLeft: 20 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />
          <button 
       type='button' 
       className="btn"
       style={{backgroundColor:'blue',color:'white',padding:'5px',marginLeft:20,borderRadius:'10px'}}
       onClick={()=>{
        handlePDFShow(true);
       }}>
           Export Report
       </button>

      </Form.Group>
      <CallListDataGrid
        rowData={FilteredData}
        callRemovePatientFromCallListAPI={callRemovePatientFromCallListAPI}
        Loading={!rowData}
        handlePDFShow={handlePDFShow}
        refreshData={()=>{
          LoadData()
        }}
      />
    </Content>
  );
};
