import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  GridColumnsToolbarButton,
  GridCellParams,
} from "@material-ui/data-grid";
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

import "./DataGrid.css";
//import MyUser from './modal';
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SanitizationRequestGetByStatus } from "../../../api/sanitizationRequestGetByStatus";
import { RootState } from "../../../app/store";
import Example from "./pdf/Pdf";

import debounce from "lodash.debounce";
import { ISinglePatientFormData } from "../../../api/addSinglePatient";
import { DateRange } from "../../../components/DateRange";
//import Geocode from './GoogleComponent';

export interface ISinglePatientData extends ISinglePatientFormData {
  id: string;
}

const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    // boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    color: "white",
    height: 48,
    justifyContent: "center",
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <FormControl
        style={{ width: 200, marginRight: 20 }}
        placeholder="Search"
      /> */}
      <GridToolbarExport />
      <GridColumnsToolbarButton />
    </GridToolbarContainer>
  );
}

// const tableHeader = [
//   "sample_collection_date",
//   "name",
//   "Date of sample collection",
//   "sex",
//   "age",
//   "status_of_patient",
//   "addres",
//   "action_area",
//   "building",
// ];

export default function HeaderColumnsGrid() {
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [rowData, setRowData] = useState([]);
  const [PatientID, setPatientID] = useState("");
  const [SearchQuery, setSearchQuery] = useState("");
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);

  const [size, setSize] = useState<any>(0);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);
  };

  const debouncedFilter = useCallback(
    () =>
      debounce((q: string) => {
        if (q === "") {
          // console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

          setFilteredData(rowData);
          return;
        }
        const searcher = new RegExp(q, "i");

        const data = rowData.filter(
          (i) => JSON.stringify(i).match(searcher) !== null
        );
        // console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
        setFilteredData(data);
        return;
      }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };

  useEffect(() => {
    SanitizationRequestGetByStatus(
      startDate,
      endDate,
      "Completed",
      accessToken
    ).then((rows) => {
      // if(r.data){
      //   setRowData(
      //     r.data.map((i: any) => ({ ...i, id: nanoid(), case_id: i.id }))
      //   );
      // }
      const data = rows?.data.map((i:any)=>
      ({ ...i,
        last_online: moment(i["last_online"]).format('YYYY-MM-DD'),
         updatedAt: moment(i["updatedAt"]).format('YYYY-MM-DD'),
      
      })

      )
      if (!!data) {
        setRowData(data);
        setFilteredData(data);
      }
    });
  }, [startDate, endDate]);

  const classes = useStyles();
  const [show, setShow] = useState(false);

  const [pdfShow, setPdfShow] = useState(false);
  const tableHeader = [
    "id",
    "name",
    "sex",
    "age",
    "case_id",
    "group",
    "status",
    "last_onlined",
    "last_called",
  ];

  const handlePdfClose = () => setPdfShow(false);

  const handlePdfShow = () => {
    setPdfShow(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Sanitization Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Example data={rowData} size={size} tableHeader={tableHeader}/> */}
        </Modal.Body>
        <Modal.Footer style={{ marginRight: "45%" }}>
          <Button variant="info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={pdfShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "1200px" }}>
          <Button
            variant="info"
            onClick={handlePdfClose}
            style={{ float: "right" }}
          >
            Close
          </Button>

          <Example />
          {/* <Example
          // data={rowData}
          // size={rowData.length}
          // tableHeader={tableHeader}
          /> */}
          {/* <ShowPatientForm closeView={handleClose} data={Ob} /> */}
        </Modal.Body>
      </Modal>

      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <FormControl
          style={{ width: 200, marginLeft: 10 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />
        <div style={{ marginLeft: 20 }}>
          <DateRange setDates={setDates} />
        </div>

        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "5px",
            marginLeft: 20,
            borderRadius: "10px",
          }}
          onClick={handlePdfShow}
        >
          Export Report
        </button>
      </Form.Group>

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        <DataGrid
          columns={[
            {
              field: "id",
              hide: false,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Request Id"}</strong>
              ),
              headerAlign: "center",
              width: 150,
            },
            {
              field: "name",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },
            {
              field: "sex",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Sex"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "age",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Age"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },

            {
              field: "case_id",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Case Id"}</strong>
              ),
              width: 105,
              headerAlign: "center",
            },

            {
              field: "status",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Status"}</strong>
              ),
              headerAlign: "center",
              width: 120,
            },
            {
              field: "last_online",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Last Online"}</strong>
              ),
              width: 150,
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {moment(params.getValue("last_online")?.toString()).format(
              //         "ll"
              //       )}
              //     </strong>
              //   );
              // },
            },
            {
              field: "updatedAt",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Completed Date"}</strong>
              ),
              width: 160,
              headerAlign: "center",

              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {moment(params.getValue("updatedAt")?.toString()).format(
              //         "ll"
              //       )}
              //     </strong>
              //   );
              // },
            },
            // {
            //   field: "action",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Action"}</strong>
            //   ),
            //   width: 150,
            //   headerAlign: "center",
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <FontAwesomeIcon
            //           icon={faEye}
            //           color="blue"
            //           size="1x"
            //           onClick={handleShow}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]}
          rows={FilteredData}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
