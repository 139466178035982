import { Content } from "antd/lib/layout/layout";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCallListApi, removePatientStatus } from "../../api";
import { RootState } from "../../app/store";
import Cards from "../../components/cards";
import { ISinglePatientData } from "../patients/DataGrid";
import { Card, CardDeck } from "react-bootstrap";
import { Form, FormControl, Modal, Button } from "react-bootstrap";
import { BulletInfo } from "../../api/bulletInfo";
import moment from 'moment'

export const Bullatin = (props: any) => {
  const { accessToken } = useSelector((state: RootState) => state.user);
  // const [PatientID, setPatientID] = useState("");
  const [rowData, setRowData] = useState<any>({
    Active_case: 0,
  });

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const LoadData = () => {
    BulletInfo().then((rows) => {
      if (!!rows?.data) {
        setRowData(rows.data);

        // var obj = rows.data
      }

      console.log(rowData);
    });
  };

  // useEffect(() => {
  //   FreeTextSearch(SearchQuery);
  // }, [SearchQuery])
  useEffect(() => {
    LoadData();
  }, []);
  // const callRemovePatientFromCallListAPI = async (s: number[]) => {
  //   const ids = s.filter((id) => !isNaN(id));
  //   await removePatientStatus(ids, accessToken);
  //   LoadData();
  // };
  return (
    <Content style={{ margin: "0 16px", flex: 1 }}>
      
      <div>
        {/* <Cards /> */}
        {/* <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%",fontSize:'40px' }}
          >
            Bulletin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> */}

        <div
          style={{
            marginLeft: "20%",
            width: "500px",
            backgroundColor: "lightgray",
            borderRadius: "20px",
            textAlign: "center",
            boxShadow: "-1px 10px 27px -3px rgba(0,0,0,0.75)",
            paddingTop: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <h3
            style={{
              borderBottom: "1px solid white",
            }}
          >
            Major Disease Status
          </h3>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              marginLeft: "5%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Total Major Disease Cases = </h4>
              <h4 style={{ color: "red" }}>
                {rowData["Total COVID_+ve_cases"]}
              </h4>
            </div>
            <br/>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Dengue cases : </h4>
              <h4 style={{ color: "red" }}>
                {" "}
                {rowData?.Case_Detected_In_Last_24_Hours}
              </h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Malaria cases: </h4>
              <h4 style={{ color: "red" }}>
                {rowData?.Patient_Cured_In_Last_24_Hours}
              </h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Polio vaccination : </h4>
              <h4 style={{ color: "red" }}>
                {rowData?.Patient_Cured_In_Last_24_Hours}
              </h4>
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row"}}>
              <h4>Active Cases = </h4>
              <h4 style={{ color: "red" }}>{rowData?.Active_case}</h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>a)Home Isolation = </h4>
              <h4 style={{ color: "red" }}>{rowData?.Home_isolation}</h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>b)Hospitalized = </h4>
              <h4 style={{ color: "red" }}>{rowData?.Hospitalized}</h4>
            </div>
            <br/>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Cured = </h4>
              <h4 style={{ color: "red" }}>{rowData?.Cured}</h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Death = </h4>
              <h4 style={{ color: "red" }}>{rowData?.Death}</h4>
            </div>
            <br/>


            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Primary contact: </h4>
            
            </div>

            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>a)Home Quarantine = </h4>
              <h4 style={{ color: "red" }}>
                {rowData?.Primary_home_quarantine}
              </h4>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>b)Institutional Quarantine = </h4>
              <h4 style={{ color: "red" }}>
                {rowData?.Primary_Institutional_quarantine}
              </h4>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <h4>Secondary contact: </h4>
            
            </div>


            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>a)Home Quarantine = </h4>
              <h4 style={{ color: "red" }}>{rowData?.indirect_contact_home}</h4>
            </div>

            <div style={{ display: "flex", flexDirection: "row", marginLeft:'20px' }}>
              <h4>b)Institutional quarantine = </h4>
              <h4 style={{ color: "red" }}>
                {rowData?.indirect_contact_not_home}
              </h4>
            </div> */}
          </div>

          {/*        
            <Button variant="info" onClick={handleClose} style={{marginTop:20,marginLeft:140}} > 
            Close
          </Button>
           </div>


        </Modal.Body>
      </Modal> */}
        </div>
      </div>
    </Content>
  );
};
