import { AxiosRequestConfig } from "axios";
import { templateAPI } from "./templateAPI";

export const removePatientStatus2 = async (
  case_id: number,
  accessToken: string
) => {
    // const data = {data: ob.map(i => ({case_id: i}))}

    const data = {"case_id":case_id};

  var config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.cca.distronix.in:3443/caller/fromRemovedTo",
    headers: {
      authorization: accessToken,
    },
    data: data,
  };
  const result = await templateAPI(config);
  return result;
};
