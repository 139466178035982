import { AxiosRequestConfig } from "axios";
import { templateAPI } from "./templateAPI";

export interface ISinglePatientFormData {
  id: string;
  mob_no: string;
  email: string;
  name: string;
  age: number | null;
  sex: string;
  address: string;
  action_area: string;
  building: string;
  diabetes_report: string;
  flat_no: string;
  plot_no: string;
  street_no: string;
  landmark: string;
  // is_active: boolean;
  sample_collection_date: string;
  place_of_admition: string;
  status_of_patient: string;
  isolation_status: string;
  group_id: null;
  status: string;
  direct_contuct: string;
  no_of_direct_contact: number;
  result_of_direct_contact: string;
  status_of_direct_contact: string;
  status_of_indirect_contact: string;
  is_vaccinated: boolean;
  reporting_covid_date: string|null;
  indirect_contact: string;
  hospital_name: string;
  hospital_admition_date: string|null;
}

export const addSinglePatient = async (
  patient: ISinglePatientFormData,
  accessToken: string
) => {
  const data = JSON.stringify({ ...patient });
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://api.cca.distronix.in:3443/caller/insert",
    headers: {
      authorization: accessToken,
      "Content-Type": "application/json",
    },
    data: data,
  };

    const result = templateAPI(config);
  return result;
};
