import {AxiosRequestConfig} from 'axios';
import {templateAPI} from './templateAPI';

export const updatePatientStatus = async (ob: {case_id: number; new_status: string}[], accessToken: string) => {
    
    var config: AxiosRequestConfig = {
      method: "post",
      url: "https://api.cca.distronix.in:3443/caller/updatePatientStatus",
      headers: {
        authorization: accessToken,
      },
      data: {"data": ob},
    };
    const result = await templateAPI(config);
    return result;
}