import axios, { AxiosRequestConfig } from "axios";
export const getTimeLine = async (id:number,key:string) => {
    const config: AxiosRequestConfig = {
      method: "get",
      url: "https://api.cca.distronix.in:3443/caller/getAnswer",
      headers: {
        authorization: key,
      },
      params: {
        case_id: id
      }
    };
  
    const result = await axios(config)
      .then((res) => ({ data: res.data, success: true, error: "" }))
      .catch((e) => ({ error: e, success: false, data: undefined }));
    return result;
  };