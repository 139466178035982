import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  GridColumnsToolbarButton,
  GridCellParams,
} from "@material-ui/data-grid";
import React, { useState, useEffect, useCallback } from "react";

import "./DataGrid.css";
//import MyUser from './modal';
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SanitizationRequestGetByStatus } from "../../../api/sanitizationRequestGetByStatus";
import { RootState } from "../../../app/store";
import Example from "./pdf/Pdf";
// import { ShowPatientForm } from "../../patients/ShowPatient";
// import { ISinglePatientData } from "../../patients/DataGrid";
import { ISinglePatientFormData } from "../../../api/addSinglePatient";
import { ShowPatientForm } from "../ShowPatient";
import { nanoid } from "@reduxjs/toolkit";
import debounce from "lodash.debounce";
import { DateRange } from "../../../components/DateRange/DateRange";

export interface ISinglePatientData extends ISinglePatientFormData {
  id: string;
}

// import Example from "./pdf/Pdf";
//import Geocode from './GoogleComponent';

const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    // boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    color: "white",
    height: 48,
    justifyContent: "center",
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <FormControl
        style={{ width: 200, marginRight: 20 }}
        placeholder="Search"
      /> */}
      <GridToolbarExport />
      <GridColumnsToolbarButton />
    </GridToolbarContainer>
  );
}

export default function HeaderColumnsGrid() {
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [rowData, setRowData] = useState<ISinglePatientData[]>([]);
  const [PatientID, setPatientID] = useState("");
  const [SearchQuery, setSearchQuery] = useState("");
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [pdfShow, setPdfShow] = useState(false);
  const tableHeader = [
    "id",
    "name",
    "sex",
    "age",
    "case_id",
    "status",
    "last_online",
    "updatedAt",
  ];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);

    console.log(startDate, endDate);
  };

  const debouncedFilter = useCallback(
    () =>
      debounce((q: string) => {
        if (q === "") {
          // console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

          setFilteredData(rowData);
          return;
        }
        const searcher = new RegExp(q, "i");

        const data = rowData.filter(
          (i) => JSON.stringify(i).match(searcher) !== null
        );
        // console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
        setFilteredData(data);
        return;
      }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };

  useEffect(() => {
    SanitizationRequestGetByStatus(
      startDate,
      endDate,
      "Approved",
      accessToken
    ).then((rows) => {
      
      const data = rows?.data.map((i:any)=>
      ({ ...i,
        last_online: moment(i["last_online"]).format('YYYY-MM-DD'),
         updatedAt: moment(i["updatedAt"]).format('YYYY-MM-DD'),
      
      })

      )
      if (!!data) {
        setRowData(data);
        setFilteredData(data);
      }
    });
  }, [startDate, endDate]);

  const handlePdfClose = () => setPdfShow(false);

  const handlePdfShow = () => {
    setPdfShow(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Patient Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShowPatientForm
            closeView={handleClose}
            data={
              rowData.find(
                (i: ISinglePatientData) => i.id === PatientID
              ) as ISinglePatientData
            }
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={pdfShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ whiteSpace: "nowrap" }}
          >
            Sanitization Approved Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="danger"
            onClick={handlePdfClose}
            style={{ float: "right", marginTop: "160px" }}
          >
            Close
          </Button>
          <h5
            style={{ whiteSpace: "nowrap", marginTop: "10px", color: "blue" }}
          >
            Do You Want To Save PDF ?
          </h5>
          <Example
            data={rowData}
            size={rowData.length}
            tableHeader={tableHeader}
          />
          {/* <ShowPatientForm closeView={handleClose} data={Ob} /> */}
        </Modal.Body>
      </Modal>

      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <FormControl
          style={{ width: 200, marginLeft: 10 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />
        <div style={{ marginLeft: 20 }}>
          <DateRange setDates={setDates} />
        </div>

        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "5px",
            marginLeft: 20,
            borderRadius: "10px",
          }}
          onClick={handlePdfShow}
        >
          Export Report
        </button>
      </Form.Group>

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        <DataGrid
          columns={[
            {
              field: "id",
              hide: false,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Request Id"}</strong>
              ),
              headerAlign: "center",
              width: 150,
            },
            {
              field: "name",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },
            {
              field: "sex",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Sex"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "age",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Age"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            // {
            //   field: "group",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Group"}</strong>
            //   ),

            //   headerAlign: "center",

            //   width: 150,
            // },
            {
              field: "case_id",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Case Id"}</strong>
              ),
              width: 105,
              headerAlign: "center",
            },

            {
              field: "status",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Status"}</strong>
              ),
              headerAlign: "center",
              width: 120,
            },
            {
              field: "last_online",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Last Online"}</strong>
              ),
              width: 150,
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {moment(params.getValue("last_online")?.toString()).format(
              //         "ll"
              //       )}
              //     </strong>
              //   );
              // },
            },
            {
              field: "updatedAt",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Approved Date"}</strong>
              ),
              width: 160,
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {moment(params.getValue("updatedAt")?.toString()).format(
              //         "YYYY-MM-DD"
              //       )}
              //     </strong>
              //   );
              // },
            },
            // {
            //   field: "action",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Action"}</strong>
            //   ),
            //   width: 95,
            //   headerAlign: "center",
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <FontAwesomeIcon
            //           icon={faEye}
            //           color="blue"
            //           size="1x"
            //           onClick={()=>{
            //             setPatientID(params.id.toString());
            //             handleShow();
            //           }}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]}
          rows={FilteredData}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
