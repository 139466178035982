import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, RangeWithKey } from "react-date-range";
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import format from 'date-fns/format'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Styles from './index.module.css';
import { GetSanitizationStatus } from '../../../../api/getSanitizationStatus';
import { RootState, useAppDispatch } from '../../../../app/store'

import { setPdfData  } from '../../../../slices/pdfData'

export const DateRange = () => {
 
  const dispatch = useAppDispatch()

     const selectionRange: Required<
       Pick<RangeWithKey, "startDate" | "endDate" | "key">
     > = {
       startDate: new Date(),
       endDate: new Date(),
       key: "selection",
     };
    const [SelectedDates, setSelectedDates] = useState<
      Required<Pick<RangeWithKey, "startDate" | "endDate" | "key">>
    >(selectionRange);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );

    const handleSelect = (e: any) => {
        if (e?.selection?.startDate && e?.selection?.endDate) setSelectedDates(state => { return { ...state, startDate: e.selection.startDate, endDate: e.selection.endDate } });
        else console.log(e);

        const startingDate=format(e?.selection?.startDate, "yyyy-MM-dd")
        const endingDate=format(e?.selection?.endDate, "yyyy-MM-dd")

        GetSanitizationStatus(startingDate, endingDate).then((rowData) =>
      {
        dispatch(setPdfData(rowData?.data))
      }
      )
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      

    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <Button
          aria-describedby={id}
                variant="outlined"
                color="primary"
          style={{ color: "Black" }}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            size="1x"
            className={Styles.CalenderIcon}
            color="red"
            icon={faCalendarAlt}
          />
          {format(SelectedDates.startDate, "yyyy-MM-dd") +
            " - " +
            format(SelectedDates.endDate, "yyyy-MM-dd")}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <DateRangePicker ranges={[SelectedDates]} onChange={handleSelect} />
        </Popover>
      </>
    );
};
