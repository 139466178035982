import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import { LoginWithJWTApi } from "../api";

export interface IUserInfoBase {
  id: number;
  name: string;
  accessToken: string;
}
export interface IUserInfo extends IUserInfoBase {
  mob_no: string;
  email: string;

  age: number;
  sex: string;
  address: string;
  action_area: string;
  building: string;
  createdAt: string;
  updatedAt: string;
}

export interface IUserState extends IUserInfoBase {
  loggedIn: boolean;
  loggingIn: boolean;
}

const initialUserState: IUserState = {
  id: -1,
  name: "",
  loggedIn: false,
  loggingIn: false,
  accessToken: "",
};

export const loginByUsernamePassword = createAsyncThunk<
  IUserInfoBase,
  { username: string; password: string }
>(
  "user/loginByUsernamePassword",
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    const user = await LoginWithJWTApi(username, password);
    // console.log({user})
    return user;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState as IUserState,
  reducers: {
    insertUserInfo: (state: IUserState, action: PayloadAction<IUserInfo>) => {
      state = {
        ...state,
        ...action.payload,
        loggedIn: true,
        loggingIn: false,
      };
      return state;
    },
    removeUserInfo: (state: IUserState, action: PayloadAction) => {
      return initialUserState;
    },
    setAccessToken: (state: IUserState, action: PayloadAction<any>) => {
        return {
          ...state,
          accessToken:action.payload.accessToken
        }
    }
  },
  extraReducers: {
    [loginByUsernamePassword.fulfilled.type]: (
      state: IUserState,
      action: PayloadAction<IUserInfoBase>
    ) => {
      state.loggedIn = true;
      state.loggingIn = false;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.accessToken = action.payload.accessToken;
    },
    [loginByUsernamePassword.pending.type]: (
      state: IUserState,
      action: PayloadAction<undefined>
    ) => {
      state.loggedIn = false;
      state.loggingIn = true;
    },
    [loginByUsernamePassword.rejected.type]: (
      state: IUserState,
      action: PayloadAction<
        unknown,
        string,
        {
          arg: string;
          requestId: string;
          rejectedWithValue: boolean;
          requestStatus: "rejected";
          aborted: boolean;
          condition: boolean;
        },
        SerializedError
      >
    ) => {
      state.loggedIn = false;
      state.loggingIn = false;
    },
    //   builder.addCase(
    //     loginByMobile.fulfilled,
    //       (state: IUserState, action: PayloadAction<IUserInfo>) => {
    //       state = {...action.payload, loggedIn: true, loggingIn: false};
    //     },
    //   );
    //     builder.addCase(
    //       loginByMobile.pending,
    //   (state: IUserState, action: PayloadAction<undefined>) => {
    //       state.loggedIn = false;
    //       state.loggingIn = true;
    //   },
    //     );
    //     builder.addCase(
    //       loginByMobile.rejected,
    //   (state: IUserState, action: PayloadAction<unknown, string, { arg: string; requestId: string; rejectedWithValue: boolean; requestStatus: "rejected"; aborted: boolean; condition: boolean; }, SerializedError>) => {
    //     state.loggedIn = false;
    //     state.loggingIn = false;
    //   },
    //     );
  },
});

// Action creators are generated for each case reducer function
export const { insertUserInfo, removeUserInfo,setAccessToken } = userSlice.actions;
