import axios, { AxiosRequestConfig } from "axios";
import { templateAPI } from './templateAPI';

export const addSanitizationRequest = async (case_id: number, accessToken: string) => {
    const data = {"case_id": case_id}

    const config: AxiosRequestConfig = {
      method: "post",
      url: "https://api.cca.distronix.in:3443/caller/newSenitizationRequest",
      headers: {
        authorization: accessToken,
      },
      data: data,
    };
    const result = await axios(config);
    return result;
}