import { FunctionComponent } from "react";
import { CallCenter } from "../pages/callcenter/callcenter";
import { Login } from "../pages/login";
import { Patients } from "../pages/patients/patients";
import { Sanitization } from "../pages/sanitization";
import { TemplateLayout } from "./showTemplate";
import { Reports } from "../pages/reports";
import { Bullatin } from "../pages/bullatin";
import NewsFeed from "../pages/NewsFeed/NewsFeed";

export interface LayoutRoute {
  Component: FunctionComponent;
  path: string;
  title: string;
}

export const GuestRoutes: LayoutRoute[] = [
  {
    Component: TemplateLayout,
    path: "/template",
    title: "Template",
  },
  {
    Component: Login,
    path: "/signin",
    title: "Sign In",
  },
];
export const UserRoutes: LayoutRoute[] = [
  {
    Component: TemplateLayout,
    path: "/template",
    title: "Template",
  },
  {
    Component: CallCenter,
    path: "/callcenter",
    title: "Call Center",
  },
  {
    Component: Patients,
    path: "/patients",
    title: "Patients",
  },
  {
    Component: Sanitization,
    path: "/sanitization",
    title: "Sanitization",
  },
  {
    Component: Reports,
    path: "/reports",
    title: "Reports",
  },
  {
    Component: Bullatin,
    path: "/bullatin",
    title: "Bullatin",
  },
  {
    Component: NewsFeed,
    path: "/NewsFeed",
    title: "News Feed",
  },
];
