import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  GridColumnsToolbarButton,
  GridCellParams,
} from "@material-ui/data-grid";
import React, { useState, useEffect, useCallback } from "react";
import { confirmAlert } from "react-confirm-alert";

import "./PatientDataGrid.css";
import "../../resources/css/rowColors.css";
//import MyUser from './modal';
import { Modal, Form, FormControl, Button, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetAllPatientApi, updatePatientStatus } from "../../api";
import { RootState } from "../../app/store";
import moment from "moment";
import { ShowPatientForm } from "../patients/ShowPatient";
import { ISinglePatientFormData } from "../../api";
import { getbgcolor } from "../../utils/colors";
import { Loading } from "../../components/loading";
import debounce from "lodash.debounce";
import Example from "./pdf/Pdf";
import { Timeline } from "@material-ui/lab";
import { getTimeLine } from "../../api/getTimeLine";
import CustomizedTimeline from "../../components/Timeline";
import "../../components/timeline.css";
export interface ISinglePatientData extends ISinglePatientFormData {
  id: string;
}
const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    // boxShadow: '5px 5px 5px 5px rgba(19, 8, 173, 0.3)',
    color: "white",
    height: 48,
    // padding: '0 30px',
    justifyContent: "center",
    marginTop: 20,
    textAlign: "center",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer style={{ width: "100%" }}>
      <div className="btn btn-light mr-2" role="button">
        <GridColumnsToolbarButton />
      </div>
      <div className="btn btn-light mr-2" role="button">
        <GridToolbarExport />
      </div>
      {/* <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: 8,
          gap: 8,
        }}
      >
        <Form.Label style={{ color: "white", margin: 0, fontWeight: "bold" }}>
          Set selected as{" "}
        </Form.Label>
        <Form.Control as="select" style={{ width: 120 }}>
          <option value="ACTIVE">ACTIVE</option>
          <option value="CURED">CURED</option>
          <option value="EXPIRED">EXPIRED</option>
        </Form.Control>
      </Form.Group> */}
    </GridToolbarContainer>
  );
}

export const PatientMaster: React.FC<{
  rowData: ISinglePatientData[];
  callUpdatePatientStatusAPI: (
    patient_id: number,
    new_status: string
  ) => Promise<void>;
  callRemovePatientFromCallListAPI: (s: number[]) => Promise<void>;
  Loading: boolean;
  handlePDFShow: (input?: boolean) => boolean;
  refreshData: ()=> void;
  // setPdfShow: any;
}> = ({ rowData, callUpdatePatientStatusAPI,callRemovePatientFromCallListAPI, Loading,refreshData, handlePDFShow }) => {
  //const { patients, isError, isLoading  } = usePatientData();

  const classes = useStyles();
  const [show, setShow] = useState(false);

  const { accessToken } = useSelector((state: RootState) => state.user);
  const [timelineData, setTimelineData] = useState<any>([]);

  const [ShowUpdateDialog, setShowUpdateDialog] = useState(false);
  const [ShowRemoveDialog, setShowRemoveDialog] = useState(false);

  const [Ob, setOb] = useState<any>();
  const handleClose = () => {
    setShow(false);
    refreshData();
  }

  const [id, setId] = useState<any>(0);

  const handleShow = (params: ISinglePatientData) => {
    setOb(params);
    setShow(true);
    setId(params.id);
  };

  const tableHeader = [
    "sample_collection_date",
    "name",
    "mob_no",
    "sex",
    "age",
    "status",
    "address",
    "action_area",
    "building",
  ];

  useEffect(() => {
    getTimeLine(id, accessToken).then((data) => {
      if(!!data?.data?.data)
      setTimelineData(Object.values(data?.data?.data));
      // console.log(Object.values(data?.data?.data));
    });

    // fetch('/data2.json')
    // .then(response => response.json())
    // .then(data => {

    //   setTimelineData(Object.values(data?.data))
    // });
  }, [id]);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Patient Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="Patient"
            id="uncontrolled-tab-example"
            style={{ display: "flex", justifyContent: "center", fontSize: 16 }}
          >
            <Tab eventKey="Patient" title="Patient Information">
              <ShowPatientForm closeView={handleClose} data={Ob} />
            </Tab>
            <Tab eventKey="Health" title="Health Timeline">
              <Timeline align="alternate" style={{ width: 800 }}>
                {!!timelineData && timelineData.length > 0 ? (
                  timelineData.map((data: any) => (
                    <CustomizedTimeline data={data} />
                  ))
                ) : (
                  <h2 style={{ margin: 20 }}>User Has Not Responded Yet</h2>
                )}
              </Timeline>
              <Button
                style={{ marginTop: 10, marginLeft: "45%" }}
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      <Modal
        show={handlePDFShow()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="info"
            onClick={() => {
              handlePDFShow(false);
            }}
            style={{ float: "right" }}
          >
            Close
          </Button>
          <Example
            data={rowData}
            size={rowData.length}
            tableHeader={tableHeader}
          />
          {/* <ShowPatientForm closeView={handleClose} data={Ob} /> */}
        </Modal.Body>
      </Modal>

      {/* <button 
       type='button' 
       className="btn"
       style={{backgroundColor:'blue',color:'white',padding:'5px',marginTop:'10px',marginBottom:'10px',marginLeft:'85%',borderRadius:'10px'}}
       onClick={handlePdfShow}>
           Export Report
       </button> */}

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        {" "}
        <DataGrid
          columns={[
            {
              field: "sl_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Id"}</strong>
              ),
              headerAlign: "center",
              width: 80
            },
            {
              field: "citizen_id",
              hide: true,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"id"}</strong>
              ),
              headerAlign: "center",
            },
            {
              field: "sample_collection_date",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Collection Date"}</strong>
              ),
              width: 160,
              align: "center",
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {" "}
              //       {moment(
              //         params.getValue("sample_collection_date")?.toString()
              //       ).format("YYYY-MM-DD")}
              //     </strong>
              //   );
              // },
            },
            {
              field: "name",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },
            {
              field: "mob_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Number"}</strong>
              ),

              headerAlign: "center",
              width: 140,
            },
            {
              field: "sex",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Sex"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "age",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Age"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            // {
            //   field: 'group',
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{color:'#fff'}}>
            //       {'Group'}

            //     </strong>
            //   ),

            //   headerAlign: 'center',

            //   width: 150,

            // },
            {
              field: "case_id",
              hide: true,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Case Id"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "status",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Status"}</strong>
              ),

              renderCell: (p: GridCellParams) => {
                const s = p.getValue("status")?.toString();
                return (
                  <select
                    defaultValue={s}
                    onChange={async (e) => {
                      const s = parseInt(p?.getValue("id")?.toString() || "");
                      const value = e.currentTarget.value;
                      confirmAlert({
                        title: "Confirm to Update",
                        message: "Are you sure to do update this?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: async () => {
                              await callUpdatePatientStatusAPI(s, value);
                            },
                          },
                          {
                            label: "No",
                            onClick: () => {},
                          },
                        ],
                      });
                    }}
                  >
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="CURED">CURED</option>
                    <option value="EXPIRED">EXPIRED</option>
                  </select>
                );
              },
              headerAlign: "center",
              width: 150,
            },

            // {
            //   field: 'lastOnlined',
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{color:'#fff'}}>
            //       {'Last Online'}

            //     </strong>

            //   ),
            //   width: 150,
            //   headerAlign: 'center',
            // },
            {
              field: 'status_of_patient',
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{color:'#fff'}}>
                  {'Status of Patient'}

                </strong>

              ),
              width: 170,
              headerAlign: 'center',
            },
            {
              field: "address",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Address"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },

            {
              field: "action_area",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Action Area"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "building",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Building"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "flat_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Flat No"}</strong>
              ),
              width: 100,
              headerAlign: "center",
            },
            {
              field: "plot_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Plot No"}</strong>
              ),
              width: 100,
              headerAlign: "center",
            },
            {
              field: "street_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Street No"}</strong>
              ),
              width: 120,
              headerAlign: "center",
            },
            {
              field: "landmark",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Landmark"}</strong>
              ),
              width: 120,
              headerAlign: "center",
            },
            {
              field: "isolation_status",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Isolation"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "place_of_admition",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Place Of Admission"}</strong>
              ),
              width: 185,
              headerAlign: "center",
            },
            {
              field: "direct_contuct",
              
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Direct Contact"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "no_of_direct_contact",
              
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Number of Direct Contact"}</strong>
              ),
              width: 225,
              headerAlign: "center",
            },
            {
              field: "result_of_direct_contact",
             
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Result Of Direct Contact"}</strong>
              ),
              width: 220,
              headerAlign: "center",
            },
            {
              field: "status_of_direct_contact",
        
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Status Of Direct Contact"}</strong>
              ),
              width: 220,
              headerAlign: "center",
            },

            {
              field: "indirect_contact",
              
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Indirect Contact"}</strong>
              ),
              width: 160,
              headerAlign: "center",
            },

            {
              field: "feedback_time",
              
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Feedback Time"}</strong>
              ),
              width: 160,
              headerAlign: "center",
            },


            {
              field: "action",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Action"}</strong>
              ),
              width: 150,
              headerAlign: "center",
              renderCell: (params) => {
                return (
                  <>
                  <div className="btn btn-light mr-2" role="button">
                    <FontAwesomeIcon
                      icon={faEye}
                      size="1x"
                      onClick={() => {
                        // setPatientID(params.id.toString());
                        handleShow(params.row as ISinglePatientData);
                      }}
                    />
                  </div>
                   <div className="btn btn-light mr-2" role="button">
                   <FontAwesomeIcon
                     icon={faTimes}
                     size="1x"
                     onClick={() => {
                       const s = parseInt(
                         params?.getValue("id")?.toString() || ""
                       );
                       confirmAlert({
                         title: "Confirm to Remove",
                         message:
                           "Are you sure to remove this from patient master?",
                         buttons: [
                           {
                             label: "Yes",
                             onClick: async () => {
                               callRemovePatientFromCallListAPI([s]);
                             },
                           },
                           {
                             label: "No",
                             onClick: () => {},
                           },
                         ],
                       });

                       // setPatientID(s.toString());
                       // handleShow(params);
                     }}
                   />
                 </div>
                 </>
                );
              },
            },
          ]}
          rows={rowData}
          getRowClassName={(p) =>
            `custom-bg-${getbgcolor(p.getValue("status")?.toString())}`
          }
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
          loading={Loading}
        />
      </div>
    </>
  );
};
