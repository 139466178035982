import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootState } from "../app/store";
import { Header } from "../components/header";
import { SideBar } from "../components/SideBar";
import { Button, Modal, Form, Alert } from "react-bootstrap";

import { UserRoutes } from "./route";
import { excelUpload } from "../api/excelUpload";
import { AddNewPatientForm, UploadMultiplePatients } from "../components/AddNewPatients";
import { Bullatin } from "../pages/bullatin";

export const UserLayout = () => {
  const [show, setShow] = useState(false);
  const [bulletinShow, setBulletinShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleBulletinClose = () => setBulletinShow(false);
  const handleBulletinShow = () => setBulletinShow(true);
  const LayoutPath = "/user";
  const { loggedIn: flag, name } = useSelector(
    (state: RootState) => state.user
  );
  //console.log({ flag });

  const getRoutes = () => {
    return UserRoutes.map((item, key) => (
      <Route path={LayoutPath + item.path} key={key}>
        <item.Component />
      </Route>
    ));
  };
  if (!flag && document.referrer !== 'http://nkda.shc.distronix.in/') return <Redirect to="/guest/signin" />;
  return (
    <>
      <div
        className="App"
        style={{
          flex: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
          }}
        >
          <SideBar
            UserAvatar="/user-profile.png"
            UserName={name}
            UserDesignation="Caller"
            AddNewPatientHandler={setShow}
          />
          <Switch>
            {getRoutes()}
            {/* <Route path="/">
              <Redirect to="/user/callcenter" />
            </Route> */}
          </Switch>
        </div>
      </div>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onEscapeKeyDown={() => setShow(false)}
        onBackdropClick={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Add New Patient
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadMultiplePatients closeView={handleClose} showView={handleShow} />
          <hr />
          <p style={{ fontSize: 12, fontWeight: "lighter", color: "grey" }}>
            Click <b>Cancel</b> or Press 'Esc' key to close this form.
          </p>
          <h4 className="heading" style={{ color: "red", textAlign: "center" }}>
            OR
          </h4>
          <AddNewPatientForm closeView={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal
        show={bulletinShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onEscapeKeyDown={() => setShow(false)}
        onBackdropClick={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Add New Patient
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <UploadMultiplePatients closeView={handleBulletinClose} showView={handleShow} /> */}
          <Bullatin closeView={handleBulletinClose} showView={handleBulletinShow}/>
        </Modal.Body>
      </Modal>

    </>
  );
};
