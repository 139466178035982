import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch } from "../app/store";
import { removeUserInfo } from "../slices";

export const Header = () => {
  const dispatch = useAppDispatch();
  return (
    <div
      className="banner-wrapper"
      style={{
        backgroundColor: "#272343",
        width: "100%",
        height: 60,
        color: "#fff",
        paddingLeft: 10,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 10,
      }}
    >
      <div className="logo">
        <img
          style={{
            width: "80px",
            height: "50px",
            marginTop: "20px",
          }}
          src={"/logo.png"}
          alt="NKDA"
        />
        <img
          style={{
            width: "80px",
            height: "50px",
            marginTop: "20px",
          }}
          src={"/logo2.png"}
          alt="NKDA"
        />
      </div>
      <div style={{fontSize:'1.5rem',color:'#6AABC1' }}>NKDA MAJOR DISEASE DASHBOARD</div>
      <Button
        style={{ backgroundColor: "#ffd803", color: "#272343" }}
        onClick={async () => {
          await dispatch(removeUserInfo());
        }}
      >
        <FontAwesomeIcon icon={faSignOutAlt} size="lg" color="#272343" />
        {" Log out"}
      </Button>
    </div>
  );
};
