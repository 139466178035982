import React, { useEffect, useState } from "react";
import "./App.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { GuestLayout, UserLayout } from "./layout";
import { SnackbarProvider } from "notistack";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.light.css";
import { RootState, useAppDispatch } from "./app/store";
import { setAccessToken } from "./slices";

export const Application = (props:any) => {

  const flag = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useAppDispatch()


    if(!!window.location.href.split('?')[1]) {

      dispatch(setAccessToken({accessToken:window.location.href.split('?')[1]}))
    }
     

  return (
    <Router>
      <SnackbarProvider maxSnack={4}>
        <Switch>
          <Route exact path="/">
            {flag || document.referrer === 'http://nkda.shc.distronix.in/'  ? (
              <Redirect to="/user/patients" />
            ) : (
              <Redirect to="/guest/signin" />
            )}
          </Route>
          <Route path="/guest" render={() => <GuestLayout />} />
          <Route path="/user" render={() => <UserLayout />} />
        </Switch>
      </SnackbarProvider>
    </Router>
  );
}

