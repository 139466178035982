import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faEye,faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  gridColumnsSelector,
  GridColumnsHeader,
  GridColumnsToolbarButton,
  GridCellParams,
} from "@material-ui/data-grid";
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import "./DataGrid.css";
import { confirmAlert } from "react-confirm-alert";
//import MyUser from './modal';
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetAllNonNkdaPatients } from "../../../api/getAllNonNkdaPatients";
import Example from "./pdf/Pdf";
import { ISinglePatientFormData } from "../../../api/addSinglePatient";

import debounce from "lodash.debounce";
import { removePatientStatus2  } from "../../../api";
import { RootState } from "../../../app/store";
export interface ISinglePatientData extends ISinglePatientFormData {
  id: string;
}

//import Geocode from './GoogleComponent';

const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    justifyContent: "center",
    marginTop: 20,
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <FormControl
        style={{ width: 200, marginRight: 20 }}
        placeholder="Search"
      /> */}
      <GridToolbarExport />
      <GridColumnsToolbarButton />
    </GridToolbarContainer>
  );
}
function newLineFixed(items: any) {
  const newItems = items.map((item: any) => {
    return {
      ...item,
      address:item.address.replace(/\n/g, ' '),
      "status_of_patient": item["status_of_patient"].replace(/\n/g, " "),
      "sample_collection_date": moment(item["sample_collection_date"]).format('YYYY-MM-DD'),
       name:item.name.replace(/\n/g,' '),
    };
  });
  console.log(newItems);
  return newItems;
}
const tableHeader = [
  "sample_collection_date",
  "name",
  "mob_no",
  "sex",
  "age",
  "status",
  "address",
  "action_area",
  "building",
];

export default function HeaderColumnsGrid() {
  //   const { accessToken } = useSelector((state: RootState) => state.user);
  const [rowData, setRowData] = useState<any[]>([]);
  const [size, setSize] = useState<any>(0);
  const [SearchQuery, setSearchQuery] = useState("");
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);
  const { accessToken } = useSelector((state: RootState) => state.user);

  const debouncedFilter = useCallback(
    () =>
      debounce((q: string) => {
        if (q === "") {
          // console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

          setFilteredData(rowData);
          return;
        }
        const searcher = new RegExp(q, "i");

        const data = rowData.filter(
          (i) => JSON.stringify(i).match(searcher) !== null
        );
        // console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
        setFilteredData(data);
        return;
      }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };
  const LoadData = () => {
    GetAllNonNkdaPatients().then((rowData) => {
      const arr = newLineFixed(rowData?.data?.RemovedPatient);
      setSize(rowData?.data?.total);

      if (!!arr) {
        setRowData(arr);
        setFilteredData(arr);
      }
    });
  };

  const callRemovePatientFromCallListAPI = async (s: number) => {
    // const ids = s.filter((id) => !isNaN(id));
    await removePatientStatus2(s, accessToken);
    LoadData();
  };

  useEffect(() => {
    LoadData();
  }, []);

  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        style={{ overflow: "scroll" }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ whiteSpace: "nowrap" }}
          >
            Non Nkda Patients Data Sheet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="danger"
            onClick={handleClose}
            style={{ float: "right", marginTop: "160px" }}
          >
            Close
          </Button>
          <h5
            style={{ whiteSpace: "nowrap", marginTop: "10px", color: "blue" }}
          >
            Do You Want To Save PDF ?
          </h5>

          <Example data={rowData} size={size} tableHeader={tableHeader} />
        </Modal.Body>
      </Modal>

      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <FormControl
          style={{ width: 200, marginLeft: 40 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />

        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "5px",
            marginLeft: 20,
            borderRadius: "10px",
          }}
          onClick={handleShow}
        >
          Export Report
        </button>
      </Form.Group>

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        <DataGrid
          columns={[
            {
              field: "id",
              hide: false,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"ID"}</strong>
              ),
              headerAlign: "center",
            },
            {
              field: "sample_collection_date",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Collection Date"}</strong>
              ),
              width: 160,
              align: "center",
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {" "}
              //       {moment(
              //         params.getValue("sample_collection_date")?.toString()
              //       ).format("ll")}
              //     </strong>
              //   );
              // },
            },
            {
              field: "name",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },

            {
              field: "mob_no",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Number"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },
            {
              field: "sex",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Sex"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "age",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Age"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "status",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Status"}</strong>
              ),

              headerAlign: "center",
              width: 140,
            },

            {
              field: "address",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Address"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "action_area",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Action Area"}</strong>
              ),
              width: 130,
              headerAlign: "center",
            },
            {
              field: "building",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Building"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "action",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Action"}</strong>
              ),
              width: 100,
              headerAlign: "center",
              renderCell: (params) => {
                return (
                  <>
              
                   <div className="btn btn-light mr-2" role="button">
                   <FontAwesomeIcon
                     icon={faTimes}
                     size="1x"
                     onClick={() => {
                       const s = parseInt(
                         params?.getValue("id")?.toString() || ""
                       );
                       confirmAlert({
                         title: "Confirm to Remove",
                         message:
                           "Are you sure to remove this from Non Nkda list?",
                         buttons: [
                           {
                             label: "Yes",
                             onClick: async () => {
                               callRemovePatientFromCallListAPI(s);
                             },
                           },
                           {
                             label: "No",
                             onClick: () => {},
                           },
                         ],
                       });

                       // setPatientID(s.toString());
                       // handleShow(params);
                     }}
                   />
                 </div>
                 </>
                );
              },
            },

            // {
            //   field: "lastCalled",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Last Called"}</strong>
            //   ),
            //   width: 150,
            //   headerAlign: "center",
            // },
            // {
            //   field: "action",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Action"}</strong>
            //   ),
            //   width: 150,
            //   headerAlign: "center",
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <FontAwesomeIcon
            //           icon={faEye}
            //           color="blue"
            //           size="1x"
            //           onClick={handleShow}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]}
          rows={FilteredData}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
