import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";

import moment from "moment";
import "./timeline.css";
function TypographyData(props: any) {
  return (
    <div>
      {props.index !== 0 && (
        <>
          <Typography variant="h6" component="h1" style={{ fontSize: "16px" }}>
            {moment(props.tdata.createdAt).format("LT")}
          </Typography>
          <Typography>
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  marginTop: "10px",
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>Health : </p>
                <p style={{ color: "red" }}>{props.tdata.ans["health"]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>Fever : </p>
                <p style={{ color: "red" }}>{props.tdata.ans["fever"]}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "12px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Breathing Problem :
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["breathing_issues"]}
                </p>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "12px",
                }}
              >
          
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Consuming Paracetamol :
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["consuming_paracetamol"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Measuring O2 Regularly:
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["measuring_o2_regularly"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Measuring O2 Frequency:
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["measuring_o2_regularly"]}
                </p>
              </div>
             
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Breathing Issues :
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["breathing_issues"]}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 0.5,
                  fontSize: "14px",
                }}
              >
                <p style={{ fontWeight: 530, color: "blue" }}>
                  Food Delivery :
                </p>
                <p style={{ color: "red" }}>
                  {props.tdata.ans["food_delivery"]}
                </p>
              </div> */}
            </>
          </Typography>
        </>
      )}
    </div>
  );
}

export default TypographyData;
