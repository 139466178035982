import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faPhone, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  GridCellParams,
  GridColumnsToolbarButton,
} from "@material-ui/data-grid";
import React, { useState, useEffect } from "react";

import "./DataGrid.css";
import "../../resources/css/rowColors.css";
//import MyUser from './modal';
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetCallListApi, removePatientStatus } from "../../api";
import { RootState } from "../../app/store";
import Geocode from "../../components/GoogleComponent";
import { nanoid } from "@reduxjs/toolkit";
import moment from "moment";
import { ShowPatientForm } from "./ShowPatient";
import { ISinglePatientData } from "../patients/DataGrid";
import { getbgcolor } from "../../utils/colors";
import { Loading } from "../../components/loading";
import { confirmAlert } from "react-confirm-alert";
import Example from "./pdf/Pdf";

const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    // boxShadow: '5px 5px 5px 5px rgba(19, 8, 173, 0.3)',
    color: "white",
    height: 48,
    backgroundColor: "#2d334a",
    // padding: '0 30px',
    justifyContent: "center",
    marginTop: 20,
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <div className="btn btn-light mr-2" role="button">
        <GridColumnsToolbarButton />
      </div>
      <div className="btn btn-light mr-2" role="button">
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );
}

export const CallListDataGrid: React.FC<{
  rowData: ISinglePatientData[];
  callRemovePatientFromCallListAPI: (s: number[]) => Promise<void>;
  Loading: boolean;
  refreshData: ()=> void;
  handlePDFShow: (input?:boolean)=>boolean;
}> = ({rowData,callRemovePatientFromCallListAPI, Loading,refreshData,handlePDFShow}) => {
  // const { callList, isError, isLoading } = useCallList();
  
  const classes = useStyles();
  const [show, setShow] = useState(false);
  

  const [Ob, setOb] = useState<any>();
  const handleClose = () => {
    setShow(false);
    refreshData();
  };
  

  const handleShow = (params: GridCellParams) => {
    setOb(params.row as ISinglePatientData);
    setShow(true);
  };
  const [PatientID, setPatientID] = useState("");
  // const [showGroup, setShowGroup] = useState(false)
  // const [showEdit, setShowEdit] = useState(false)
  // const handlePdfShow = () => {
    
  //   setPdfShow(true);
  // };
const tableHeader=["sample_collection_date","name","sex","age","status","address","action_area","building",]

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Patient Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShowPatientForm closeView={handleClose} data={Ob} />
        </Modal.Body>
      </Modal>

      <Modal
        show={handlePDFShow()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{whiteSpace:'nowrap'}}
          >
         Call List Data Sheet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Button variant="danger" onClick={()=> { handlePDFShow(false)}} style={{float:'right',marginTop:'135px'}}> 
            Close
          </Button>
          <h5 style={{whiteSpace:'nowrap',marginTop:'10px',color:'blue'}}>Do You Want To Save PDF ?</h5> 
        <Example data={rowData} size={rowData.length} tableHeader={tableHeader}/>
          {/* <ShowPatientForm closeView={handleClose} data={Ob} /> */}
        </Modal.Body>
      </Modal>

      

    

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
        }}
      >
          <DataGrid
            columns={[
              {
                field: "id",
                hide: true,
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Case ID"}</strong>
                ),
                width: 110,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "sample_collection_date",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Collection Date"}</strong>
                ),
                width: 160,
                align: "center",
                headerAlign: "center",
                // valueFormatter: (params) =>
                //   moment(
                //     params.getValue("sample_collection_date")?.toString()
                //   ).format("ll"),
                // renderCell: (params: GridCellParams) => {
                //   return (
                //     <strong>
                //       {" "}
                //       {moment(
                //         params.getValue("sample_collection_date")?.toString()
                //       ).format("ll")}
                //     </strong>
                //   );
                // },
              },
              {
                field: "name",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
                ),
                headerAlign: "center",
                width: 150,
              },
              {
                field: "mob_no",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Number"}</strong>
                ),
  
                headerAlign: "center",
                width: 140,
              },
              {
                field: "sex",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Sex"}</strong>
                ),

                headerAlign: "center",
                width: 100,
              },
              {
                field: "age",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Age"}</strong>
                ),

                headerAlign: "center",
                width: 100,
              },
              // {
              //   field: 'group',
              //   renderHeader: (params: GridColumnHeaderParams) => (
              //     <strong style={{ color: '#fff' }}>
              //       {'Group'}

              //     </strong>
              //   ),

              //   headerAlign: 'center',

              //   width: 150,

              // },
              // {
              //   field: "case_id",
              //   renderHeader: (params: GridColumnHeaderParams) => (
              //     <strong style={{ color: "#fff" }}>{"Case Id"}</strong>
              //   ),
              //   width: 150,
              //   headerAlign: "center",
              // },

              {
                field: "status",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Status"}</strong>
                ),
                headerAlign: "center",
                width: 150,
              },
              // {
              //   field: 'lastOnlined',
              //   renderHeader: (params: GridColumnHeaderParams) => (
              //     <strong style={{ color: '#fff' }}>
              //       {'Last Online'}

              //     </strong>

              //   ),
              //   width: 150,
              //   headerAlign: 'center',
              // },
              // {
              //   field: 'lastCalled',
              //   renderHeader: (params: GridColumnHeaderParams) => (
              //     <strong style={{ color: '#fff' }}>
              //       {'Last Called'}

              //     </strong>

              //   ),
              //   width: 150,
              //   headerAlign: 'center',
              // },
              {
                field: "address",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Address"}</strong>
                ),
                width: 150,
                headerAlign: "center",
              },

              {
                field: "action_area",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Action Area"}</strong>
                ),
                width: 150,
                headerAlign: "center",
              },
              {
                field: "building",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Building"}</strong>
                ),
                width: 150,
                headerAlign: "center",
              },
              {
                field: "flat_no",
                hide: true,
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Flat No"}</strong>
                ),
                width: 150,
                headerAlign: "center",
              },
              {
                field: "isolation_status",
                hide: true,
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Isolation"}</strong>
                ),
                width: 150,
                headerAlign: "center",
              },
              {
                field: "action",
                renderHeader: (params: GridColumnHeaderParams) => (
                  <strong style={{ color: "#fff" }}>{"Action"}</strong>
                ),
                width: 150,
                headerAlign: "center",
                renderCell: (params: GridCellParams) => {
                  return (
                    <>
                      <div className="btn btn-light mr-2" role="button">
                        <FontAwesomeIcon
                          icon={faPhone}
                          size="1x"
                          onClick={() => {
                            setOb(params.row as ISinglePatientData);
                            setShow(true);
                          }}
                        />
                      </div>
                      <div className="btn btn-light mr-2" role="button">
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          onClick={() => {
                            const s = parseInt(
                              params?.getValue("id")?.toString() || ""
                            );
                            confirmAlert({
                              title: "Confirm to Remove",
                              message:
                                "Are you sure to remove this from call list?",
                              buttons: [
                                {
                                  label: "Yes",
                                  onClick: async () => {
                                    callRemovePatientFromCallListAPI([s]);
                                  },
                                },
                                {
                                  label: "No",
                                  onClick: () => {},
                                },
                              ],
                            });

                            // setPatientID(s.toString());
                            // handleShow(params);
                          }}
                        />
                      </div>
                    </>
                  );
                },
              },
            ]}
            rows={rowData}
            getRowClassName={(p) =>
              `custom-bg-${getbgcolor(p.getValue("status")?.toString())}`
            }
            checkboxSelection
            components={{
              Toolbar: CustomToolbar,
            }}
            loading={Loading}
          />
      </div>
    </>
  );
};
