import React, { useEffect } from "react";
import { useState } from "react";
import { plus, x } from "../assets";
// import InputBoxGrid from "./InputBoxGrid";
import { Field, useFormikContext } from "formik";
import DropdownGrid from "./DropdownGrid";

function AchieveGrid(props: any) {
  const [addList, setAddList] = useState<any>([]);
  const [id, setId] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [addData, setAddData] = useState(props?.values?.addAchieve ? props?.values?.addAchieve : false);
  const [addCounter, setAddCounter] = useState<any>(props?.values?.addAchieveCount ? props?.values?.addAchieveCount : 0);
  const [addIndex, setAddIndex] = useState<any>(props?.values?.addAchieveIndex?.length> 0 ? props?.values?.addAchieveIndex : []);
  const [skip,setSkip] = useState(true)
  const {values}:any = useFormikContext()
  // const {values} = useFormikContext()

  const handleAddClick = () => {
    setAddData(true);
    // props.values.addAchieve = true;
    setAddList([
      Object.fromEntries(props?.header?.map((key: any, i: any) => key !== 'Serial No' ? [key, ""] : [key, addList.length+1])),
      ...addList,
    ]);
    setAddCounter((prev: any) => prev + 1);
  };

  const handleDeleteClick = (index: any) => {
    // console.log(index)
    let list = [...addList];
    list = list.filter((item: any) => item['Serial No'] !== index)
    setAddList(list.map((item: any,i:any) => ({...item, 'Serial No': i+1})).reverse())
    indexBalancer(index)
  };
  const indexBalancer = (index: any)=>{
    if(index === 1 ){
      for (let i=1 ; i<= addList.length ; i++){
        props.names.forEach((name: string) => values[`${name}${i}`] = values[`${name}${i+1}`] )
      }
    }
    else if(index !== 0){
      for (let i=index ; i<= addList.length ; i++){
      props.names.forEach((name: string) => values[`${name}${i}`] = values[`${name}${i+1}`] )
      }
    }
  }

  //For updating multiple rows at once
  useEffect(() => {
    for (let i = 0; i < props?.inputList?.length; i++) {
      setId((prev: any) => [...prev, `${i}`]);
    }
    // props.onUpdate(updateIndex);
    (props?.inputList?.length === 0 && addList?.length === 0) && setAddList([Object.fromEntries(props?.header?.map((key: any, i: any) => key !== 'Serial No' ? [key, ""] : [key, 1]))])
    if(props?.inputList?.length === 0 && addList?.length === 0) values.DiseaseList = ([Object.fromEntries(props?.header?.map((key: any, i: any) => key !== 'Serial No' ? [key, ""] : [key, 1]))])
    if (props?.inputList?.length === 0) {
      for (let i = 0; i < 1; i++) {
        setAddIndex([`${i}`]);
        // props.values.addAchieveIndex = [`${i}`]
      }
    }
    props?.names?.forEach((item:any) => values[`${item}${addList.length}`] = "")
    // (props?.inputList?.length === 0 && addList?.length === 0) && props?.onAdd({ add: true, data: [...addList,Object.fromEntries(head?.map((key: any, i: any) => key !== 'Serial No' ? [key, ""] : [key, 1]))] })
  }, []);
  useEffect(() => {
    if (addData === true && addList?.length > 0) {
        setAddIndex([...addIndex, `${addCounter}`]);
        // props.values.addAchieveIndex = [...addIndex,`${addCounter}`];
    }
    // props?.onAdd({ add: addData, data: addList });/////////////////check again later///////////////////////////////
    if (!skip){
      props?.names?.forEach((item:any) => values[`${item}${addList.length}`] = "")
      // values.DiseaseList = addList
    } 
    setSkip(false);
  }, [addCounter]);
  useEffect(()=>{
    values.DiseaseList= addList
    props.onChangeValues(values)
  },[values,addList.length])

  const handleClose = () => {
    setOpen(!open);
  };
  
  console.log(values)
  return (
    <div className=" sm:w-full overflow-x-scroll sm:overflow-hidden border-[0.5px]" style={{width: "100%"}}>
      
      {props?.inputList && (
        <table className="min-w-full text-sm text-left p-4 text-gray-700" style={{minWidth: "100%",borderColor: "lightgrey"}}>
          <thead className="text-xs text-gray-700 bg-[#d1e0f4] border-b">
            {/* <tr className="text-xs font-medium bg-[#F4F2FF] whitespace-nowrap"> */}
              {props?.header?.map((item: any) => {
                return <th className="pl-2 py-2 sm:px-2 whitespace-nowrap" style={{borderColor: "lightgrey"}}>{item}</th>;
              })}
               <th className={` flex py-2 sm:px-0 justify-end `} style={{display: 'flex', alignItems: 'center', borderWidth: 1,borderTopWidth: 0,borderLeftWidth: 0,borderRightWidth: 0, paddingRight: 0,borderColor: "lightgrey"}}>
                <div className="invisible ">Add</div>
                  <img
                    src={plus}
                    onMouseDown={handleAddClick}
                    className= "cursor-pointer w-5"
                    style={{cursor: "pointer"}}
                  />
              </th>
            {/* </tr> */}
          </thead>
          <tbody>
            {(props?.inputList?.length === 0 && !addData) &&
              addList?.map((item: any, j: any) => {
                return (
                  <tr className="bg-white border-b" style={{borderColor: "lightgrey"}}>
                    {props?.names?.map((list: any, i: any) => {
                      // console.log(`${list}${j}`)
                      return (
                        <>
                        {list !== 'slno' ? <td className="py-1 w-fit pl-2" style={{borderColor: "lightgrey"}}>
                          {props?.dropdown?.includes(list) ? (
                            <DropdownGrid
                              props={{
                                options: list === "vaccination" ? props?.options : props?.diseaseOptions,
                                name: `${list}${item['Serial No']}`,
                                label: "",
                                id: `${props?.inputList?.length + j}`,
                                addnew: addIndex,
                                set: id
                              }}
                            />
                          ) 
                           :
                            <Field
                              name={`${list}${item['Serial No']}`}
                              label={""}
                              type={"date"}
                              id={`${props?.inputList?.length + j}`}
                              addnew={addIndex}
                              set={id}
                              style={{padding: 5}}
                              // error={touched[`${list}${item['Serial No']}`] && errors[`${list}${item['Serial No']}`]}
                            />
                          }
                        </td>: 
                        <td className="mt-2" style={{display: 'flex', alignItems: 'center', justifyContent: "center",borderWidth: 0}}>
                            <p style={{fontSize: 20}}>{item['Serial No']}</p>
                        </td>}
                        </>
                      );
                    })}
                  </tr>
                );
              })}
            {(addData) &&
              addList?.map((item: any, j: any) => {
                return (
                  <tr className="bg-white border-b " style={{borderColor: "lightgrey"}}>
                    {props?.names?.map((list: any, i: any) => {
                      // console.log(`${list}${j+1}`)
                      return (
                        <>
                        {list !== 'slno' ? <td className="py-1 pl-2 " style={{borderColor: "lightgrey"}}>
                          {props?.dropdown?.includes(list) ? (
                            <DropdownGrid
                              props={{
                                options: list === "vaccination" ? props?.options : props?.diseaseOptions,
                                name: `${list}${item['Serial No']}`,
                                label: "",
                                id: `${j}`,
                                addnew: props?.values?.addAchieveIndex?.length>0 ? props?.values?.addAchieveIndex: addIndex,
                                set: id
                              }}
                            />
                          ) :
                            <Field
                              name={`${list}${item['Serial No']}`}
                              label={""}
                              type={"date"}
                              id={`${props?.inputList?.length + j}`}
                              addnew={addIndex}
                              set={id}
                              style={{padding: 5}}
                              // error={touched[`${list}${item['Serial No']}`] && errors[`${list}${item['Serial No']}`]}
                            />
                          }
                        </td>: 
                        <td className="mt-2" style={{display: 'flex', alignItems: 'center', justifyContent: "center",borderWidth: 0,borderBottomWidth: 1,borderColor: "lightgrey"}}>
                            <p style={{fontSize: 20}}>{item['Serial No']}</p>
                        </td>}
                        </>
                      );
                    })}
                    {(props?.inputList?.length > 0 || addList?.length > 1) && <td className="flex justify-end " style={{display: 'flex' , justifyContent: "center", alignItems: "center", borderWidth: 0, }}>
                        <img
                          src={x}
                          onMouseDown={() => handleDeleteClick(item['Serial No'])}
                          className={
                            "cursor-pointer w-6 h-5 ml-[22px] mt-3"
                          }
                          style={{cursor: "pointer"}}
                        />
                    </td>
                    }
                  </tr>
                );
              })}
          </tbody>
          
        </table>
      )}
      {(!(props?.inputList?.length > 0) && !(addList?.length>0)) && <h1 className="text-base flex-1 flex justify-center">No Data Found!!</h1>}
    </div>
  );
}

export default AchieveGrid;
