import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { GuestRoutes } from "./route";

export const GuestLayout = () => {
  const LayoutPath = "/guest";
  const getRoutes = () => {
    return GuestRoutes.map((item, key) => (
      <Route path={LayoutPath + item.path} key={key}>
        <item.Component />
      </Route>
    ));
  };

  return (
    <div
      className="App"
      style={{
        flex: 1,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="banner-wrapper"
        style={{
          backgroundColor: "blue",
          width: "100%",
          height: 60,
          color: "#fff",
          paddingLeft: 10,
          fontWeight: "bold",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div className="logo_layout">
          <img
            style={{
              width: "80px",
              height: "50px",
            }}
            src={"/logo.png"}
            alt="NKDA"
          />
        </div>
        <div className="logo_layout">
          <img
            style={{
              width: "80px",
              height: "50px",
            }}
            src={"/logo2.png"}
            alt="NKDA"
          />
        </div>
        <div style={{ marginLeft: 20 }}>NKDA MAJOR DISEASE DASHBOARD</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <Switch>
          {getRoutes()}
          <Route path="/">
            <Redirect to="/guest/signin" />
          </Route>
        </Switch>
      </div>
    </div>
  );
};
