import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridColumnHeaderParams } from "@material-ui/data-grid";
import "./DataGrid.css";
import DeleteIcon from "./DeleteIcon.png";
import Alert from "@material-ui/lab/Alert/Alert";
import { deleteNewsFeed } from "../../api/NewsFeedAPIs/NewsFeed";

type Props = {
  data: any[];
  onClick: () => void;
};
const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    justifyContent: "center",
    marginTop: 20,
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

const NewsFeedGrid = ({ data, onClick }: Props) => {
  const classes = useStyles();

  const _deleteHanlder = async (params: any) => {
    const res = await deleteNewsFeed(params?.row?.uid);
    if (res?.status) {
      alert("The deletion has been successfully completed.");
      onClick();
    } else {
      alert("Something went wrong");
    }
  };

  const srcW = window.innerWidth;

  return (
    <div
      className={classes.root}
      style={{
        height: 590,
        width: "100%",
        textAlign: "center",
        color: "white",
      }}
    >
      <DataGrid
        columns={[
          {
            field: "id",
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => (
              <strong style={{ color: "#fff" }}>{"Sl. No."}</strong>
            ),
            headerAlign: "center",
          },
          {
            field: "heading",
            renderHeader: (params: GridColumnHeaderParams) => (
              <strong style={{ color: "#fff" }}>{"Heading"}</strong>
            ),

            headerAlign: "center",

            width: srcW > 1900 ? 350 : 250,
          },
          {
            field: "Description",
            renderHeader: (params: GridColumnHeaderParams) => (
              <strong style={{ color: "#fff" }}>{"Description"}</strong>
            ),

            headerAlign: "center",
            width: srcW > 1900 ? 750 : 460,
          },
          {
            field: "Image",
            renderHeader: (params: GridColumnHeaderParams) => (
              <strong style={{ color: "#fff" }}>{"Image"}</strong>
            ),
            width: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params: any) => {
              return (
                <img src={params.value} style={{ width: 200, height: 200 }} />
              );
            },
          },

          {
            field: "action",
            renderHeader: (params: GridColumnHeaderParams) => (
              <strong style={{ color: "#fff" }}>{"Action"}</strong>
            ),
            renderCell: (params: any) => {
              return (
                <img
                  onClick={() => _deleteHanlder(params)}
                  src={DeleteIcon}
                  style={{ width: 20, height: 20, cursor: "pointer" }}
                />
              );
            },
            headerAlign: "center",
            width: 130,
          },
        ]}
        rows={data}
      />
    </div>
  );
};

export default NewsFeedGrid;
