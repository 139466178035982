import {
  faAmbulance,
  faHandHoldingMedical,
  faSmile,
  faTemperatureHigh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { Card, CardDeck } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getKPIs } from "../api/getKPIs";
import { RootState } from "../app/store";
import { DateRange } from "./DateRange";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function DatewiseCards({ selectedDisease }: any) {
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [KPIs, setKPIs] = useState<{
    totalExpiredCase: string;
    totalCuredCase: string;
    totalActiveCase: string;
    totalNewCaseToday: string;
    totalCases: number;
    totalFeedback: string;
    totalFeedbackToday: string;
  }>({
    totalExpiredCase: "",
    totalCuredCase: "",
    totalActiveCase: "",
    totalNewCaseToday: "",
    totalCases: 0,
    totalFeedback: "",
    totalFeedbackToday: "",
  });

  const [startDate, setStartDate] = useState(moment().format());
  const [endDate, setEndDate] = useState(moment().format());
  const [loader, setLoader] = useState(false);
  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);

    console.log(startDate, endDate);
  };
  const callKPI = async (startDate: string, endDate: string) => {
    setLoader(true);
    await getKPIs(accessToken, startDate, endDate, selectedDisease).then((v) =>
      setKPIs(v)
    );
    setLoader(false);
  };
  useEffect(() => {
    callKPI(startDate, endDate);
  }, [startDate, endDate, selectedDisease]);
  return (
    <>
      <div
        style={{ margin: 20, display: "flex", alignItems: "center", gap: 10 }}
      >
        <h5 style={{ color: "#fff" }}>Date Wise</h5>
        <DateRange setDates={setDates} />
      </div>
      <CardDeck>
        <Card style={{ backgroundColor: "#1034A6", borderRadius: "20px" }}>
          <Card.Body>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faAmbulance}
                size="2x"
                color="#fff"
                style={{ marginTop: "3px" }}
              />
              {
                !loader ? (
                  <Card.Text
                    style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
                  >
                    {KPIs.totalCases}
                  </Card.Text>
                ) : (
                  "Loading..."
                )

                // <Loader
                //   type='ThreeDots'
                //   color='gray'
                //   height={100}
                //   width={100}
                //   visible={true}
                // />
              }
            </Card.Title>
            <Card.Title
              style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
            >
              TOTAL CASES
            </Card.Title>
          </Card.Body>
        </Card>
        <Card style={{ backgroundColor: "red", borderRadius: "20px" }}>
          <Card.Body>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faTemperatureHigh}
                size="2x"
                color="#fff"
                style={{ marginTop: "3px" }}
              />
              {!loader ? (
                <Card.Text
                  style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
                >
                  {KPIs.totalActiveCase}
                </Card.Text>
              ) : (
                "Loading..."
              )}
            </Card.Title>
            <Card.Title
              style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
            >
              NEW CASES
            </Card.Title>
          </Card.Body>
        </Card>

        <Card style={{ backgroundColor: "green", borderRadius: "20px" }}>
          <Card.Body>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faSmile}
                size="2x"
                color="#fff"
                style={{ marginTop: "3px" }}
              />
              {!loader ? (
                <Card.Text
                  style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
                >
                  {KPIs.totalCuredCase}
                </Card.Text>
              ) : (
                "Loading..."
              )}
            </Card.Title>

            <Card.Title
              style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
            >
              TOTAL CURED CASES
            </Card.Title>
          </Card.Body>
        </Card>

        <Card style={{ backgroundColor: "#fc6805", borderRadius: "20px" }}>
          <Card.Body>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faHandHoldingMedical}
                size="2x"
                color="#fff"
                style={{ marginTop: "3px" }}
              />
              {!loader ? (
                <Card.Text
                  style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
                >
                  {KPIs.totalActiveCase}
                </Card.Text>
              ) : (
                "Loading..."
              )}
            </Card.Title>
            <Card.Title
              style={{ fontSize: 18, textAlign: "center", color: "#fff" }}
            >
              TOTAL ACTIVE CASES
            </Card.Title>
          </Card.Body>
        </Card>

        {/* <Card style={{ backgroundColor: "#4ebfa5", borderRadius: "20px" }}>
          <Card.Body>
            <Card.Title
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faCommentMedical}
                size="2x"
                color="#fff"
                style={{ marginTop: "3px" }}
              />
              {!loader ? (
                <Card.Text
                  style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
                >
                  {KPIs.totalFeedbackToday}
                </Card.Text>
              ) : (
                "Loading..."
              )}
            </Card.Title>

            <Card.Title
              style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
            >
              Total Feed-back
            </Card.Title>
          </Card.Body>
        </Card> */}
      </CardDeck>

      {/* <br/>

    <CardDeck>

    <Card style={{ backgroundColor: "#750455", borderRadius: "20px" }}>
        <Card.Body>
          <Card.Title
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size="2x"
              color="#fff"
              style={{ marginLeft: "5px", marginTop: "3px" }}
            />
            <Card.Text
              style={{ fontSize: 30, textAlign: "center", color: "#fff" }}
            >
              {KPIs.totalExpiredCase}
            </Card.Text>
          </Card.Title>
          <Card.Title
            style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
          >
            TOTAL EXPIRED CASES
          </Card.Title>
        </Card.Body>
      </Card>

      <Card style={{ backgroundColor: "#bfb64e", borderRadius: "20px" }}>
        <Card.Body>
          <Card.Title
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon
              icon={faCommentMedical}
              size="2x"
              color="#fff"
              style={{ marginLeft: "5px", marginTop: "3px" }}
            />
            <Card.Text
              style={{ fontSize: 30, textAlign: "center", color: "#fff" }}
            >
              {KPIs.totalFeedbackToday}
            </Card.Text>
          </Card.Title>
          <Card.Title
            style={{ fontSize: 20, textAlign: "center", color: "#fff" }}
          >
            Today's Feedback
          </Card.Title>
        </Card.Body>
      </Card> */}

      {/* </CardDeck> */}
    </>
  );
}

export default DatewiseCards;
