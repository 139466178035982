import React, { Component } from "react";
import { Card, CardDeck } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../../../../app/store";
import PdfCard from "./pdfCard2";
import moment from "moment";

function Cards(props: any) {
  const data = useSelector((state: RootState) => state.pdfdata);

  const arrData = data?.value;
  var startDate: any = moment(arrData?.start_date).format("LL");
  var endDate: any = moment(arrData?.end_date).format("LL");
  var numerOfCards = arrData.total;
  return (
    <>
      {/* <div className='dates'>
        <div className='start_date' style={{ float: 'left', color: 'green', marginLeft: '20%', fontWeight: 'bold' }}>
          Start Date :{startDate}
    </div>
        <div className='end_date' style={{ float: 'right', color: 'red', marginRight: '20%', fontWeight: 'bold' }}>
          End Date :{endDate}
    </div>
      </div><br />
     
      <div className='total' style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
        Total Locations Sanitized : {arrData.total}
    </div> */}

      {!!arrData?.data && arrData?.data.length > 0 ? (
        arrData?.data.map((data: any, index: number) => (
          <PdfCard
            data={data}
            index={index}
            startDate={startDate}
            endDate={endDate}
            total={numerOfCards}
          />
        ))
      ) : (
        <h4 style={{ textAlign: "center", marginTop: "20%" }}>
          No Data To Export
        </h4>
      )}
    </>
  );
}
class PdfReport extends Component {
  render() {
    return (
      <div>
        <Cards />
      </div>
    );
  }
}

export default PdfReport;
