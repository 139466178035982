import { AxiosRequestConfig } from "axios";
import { templateAPI } from "./templateAPI";


export const GetCallListApi = async (accessToken: string) => {
  const conf: AxiosRequestConfig = {
    method: "get",
    url: "https://api.cca.distronix.in:3443/caller/callList",
    headers: {
      authorization: accessToken
    },
  };

  const result = await templateAPI(conf);
  return result.data;
};
