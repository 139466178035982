import { Content } from "antd/lib/layout/layout";
import React, { useCallback, useEffect, useState } from "react";
import Cards from "../../components/cards";
import { ISinglePatientData, PatientMaster } from "./DataGrid";
import { Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  GetAllPatientApi,
  updatePatientStatus,
  removePatientStatus,
} from "../../api";
import { RootState } from "../../app/store";
import { DownloadPdf } from "../../api/downloadPdf";
import moment from "moment";
import DatewiseCards from "../../components/DatewiseCards";
import { DateRange } from "../../components/DateRange";
import { MenuItem, Select } from "@material-ui/core";
export const Patients = () => {
  const [SearchQuery, setSearchQuery] = useState("");
  const { accessToken } = useSelector((state: RootState) => state.user);
  // const [PatientID, setPatientID] = useState("");
  const [rowData, setRowData] = useState<ISinglePatientData[]>([]);
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);

  const [pdfShow, setPdfShow] = useState<boolean>(false);
  // const [Answer, setAnswer] = useState<boolean | undefined>();
  // const [IDs, setIDs] = useState<{ case_id: number; new_status: string }[]>([]);

  const [url, setUrl] = useState("");

  const [flag, setFlag] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedDisease, setSelectedDisease] = useState("dengue");

  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);

    console.log(startDate, endDate);
  };

  const handlePdfDownload = () => {
    DownloadPdf().then((rows) => {
      if (!!rows?.data) {
        setUrl(rows.data.path);
        // var obj = rows.data
      }

      // console.log(rows.data);
    });
  };

  const debouncedFilter = useCallback(
    () =>
      debounce((q: string) => {
        if (q === "") {
          setFilteredData(rowData);
          return;
        }
        const searcher = new RegExp(q, "i");
        const data = rowData.filter(
          (i) => JSON.stringify(i).match(searcher) !== null
        );
        setFilteredData(data);
        return;
      }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };

  const LoadData = () => {
    GetAllPatientApi(accessToken).then((r) => {
      // if(!!r.data){
      //   const data = r?.data
      //   .map((i: any,index:number) => ({ ...i,
      //     sl_no: index+1,
      //     case_id: i.id,
      //     address:i.address.replace(/\n/g, ' '),
      //     place_of_admition:i.place_of_admition.replace(/\n/g, ' '),
      //     status_of_patient:i.status_of_patient.replace(/\n/g,' '),
      //     sample_collection_date: moment(i["sample_collection_date"]).format('YYYY-MM-DD'),
      //     direct_contuct:i.direct_contuct.replace(/\n/g, ' '),
      //     name:i.name.replace(/\n/g,' '),
      //   }))
      //   .filter((i: any) => i.id != null);
      // setRowData(data);
      // setFilteredData(data);
      // }
      // console.log(data)
    });
  };

  const handlePDFShow = (input?: boolean) => {
    if (typeof input === "undefined") {
      return pdfShow;
    } else {
      setPdfShow(input);
      return input;
    }
  };

  const callRemovePatientFromCallListAPI = async (s: number[]) => {
    const ids = s.filter((id) => !isNaN(id));
    await removePatientStatus(ids, accessToken);
    LoadData();
  };
  // useEffect(() => {
  //   FreeTextSearch(SearchQuery);
  // }, [SearchQuery])
  useEffect(() => {
    LoadData();
  }, []);

  const callUpdatePatientStatusAPI = async (
    patient_id: number,
    new_status: string
  ) => {
    if (!isNaN(patient_id)) {
      await updatePatientStatus(
        [
          {
            case_id: patient_id,
            new_status: new_status,
          },
        ],
        accessToken
      );
    }
    LoadData();
  };

  return (
    <Content style={{ margin: "12px 16px", flex: 1 }}>
      {/* Option to select disease Types */}

      <div className="px-3 flex space-x-2 items-center ">
        <h1 style={{ color: "#fff" }}>Please Select disase type</h1>
        <Select
          value={selectedDisease}
          onChange={(event: any) => setSelectedDisease(event.target.value)}
          label=""
          style={{
            backgroundColor: "#fff",
            borderRadius: 8,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <MenuItem value={"dengue"}>Dengue</MenuItem>
          <MenuItem value={"typhoid"}>Typhoid</MenuItem>
          <MenuItem value={"malaria"}>Malaria</MenuItem>
          <MenuItem value={"polio"}>Polio</MenuItem>
        </Select>
      </div>
      <h4
        className="body_wrapper"
        style={{ textAlign: "center", color: "#fff" }}
      >
        Patient Master for {selectedDisease}
      </h4>
      <h5 className="body_wrapper" style={{ color: "#fff" }}>
        Total
      </h5>

      <Cards selectedDisease={selectedDisease} />
      <DatewiseCards selectedDisease={selectedDisease} />
      <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <FormControl
          style={{ width: 200, marginLeft: 20 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />
        {url === "" ? (
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "blue",
              color: "white",
              padding: "5px",
              borderRadius: "10px",
              marginLeft: 20,
            }}
            onClick={() => {
              // handlePDFShow(true);
              setFlag(true);
              handlePdfDownload();
            }}
          >
            {" "}
            {!flag ? "Generate Pdf" : "Loading..."}
          </button>
        ) : (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 20 }}
          >
            Download Pdf
          </a>
        )}
        {/* <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "5px",
            borderRadius: "10px",
            marginLeft: 20,
          }}
          onClick={() => {
            handlePDFShow(true);
            handlePdfDownload();
          }}
        >
          {" "}
          Generate Pdf
        </button>

        <a href={url} target="_blank" rel="noreferrer">
          DownloadPdf
        </a> */}
      </Form.Group>

      <PatientMaster
        rowData={FilteredData}
        callUpdatePatientStatusAPI={callUpdatePatientStatusAPI}
        callRemovePatientFromCallListAPI={callRemovePatientFromCallListAPI}
        Loading={!rowData}
        handlePDFShow={handlePDFShow}
        refreshData={() => {
          LoadData();
        }}
      />
    </Content>
  );
};
