import axios from "axios";

const baseUrl = "https://api.cca.distronix.in:3443";

export const getNewsFeeds = () => {
  let config: any = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/news/get-news`,
    headers: {},
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return res;
};
export const deleteNewsFeed = (id: string) => {
  let data = JSON.stringify({
    news_id: id,
  });

  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/news/delete-news`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return res;
};

export const _createNewsFeed = ({ event_title, description, file }: any) => {
  let data = new FormData();
  data.append("event_title", event_title);
  data.append("description", description);
  data.append("file", file);

  let config: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/news/create-news`,
    data: data,
  };

  const res = axios
    .request(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

  return res;
};
