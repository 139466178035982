import Resume from "./reports";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { DateRange } from './DateRange'
import './pdf.css'
const Example = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'sanitization_Completed_Report'
  });

  return (
    <>
      <div style={{ marginLeft: '35%' }} >
        <p style={{ fontWeight: 'bold', color: 'gray', marginLeft: '5%', fontSize: '16px' }}>Select Your Date Range</p>
        <DateRange />
      </div>

      <div className=" p-6" style={{ marginTop: '10%' }}>
  {/* <div style={{display:'none'}}> */}
  <button
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4"
          onClick={handlePrint}
          style={{ marginTop: '23%', float: 'left', fontSize: '16px' }}
        >
          {" "}
        Save PDF{" "}
        </button>
  
        <Resume ref={componentRef} />
        {/* </div> */}
      
      </div>
    </>
  );
};
export default Example
