import { templateAPI } from "./templateAPI";
import { AxiosRequestConfig } from "axios";

interface IKPIs {
  totalExpiredCase: string;
  totalCuredCase: string;
  totalActiveCase: string;
  totalNewCaseToday: string;
  totalCases: number;
  totalFeedback: string;
  totalFeedbackToday: string;
}

export const getKPIs = async (
  key: string,
  sd = "",
  ed = "",
  diseaseType = "dengue"
) => {
  const config: AxiosRequestConfig = {
    method: "get",
    url: `https://api.cca.distronix.in:3443/caller/kpi?startDate=${sd}&endDate=${ed}&disease_type=${diseaseType}`,
    headers: {
      authorization: key,
    },
  };
  const result = await templateAPI(config);
  console.log("Inside getKPIs: ", result);
  const updatedResult = !!result.data
    ? result.data.reduce((p: any, c: any) => ({ ...p, ...c }), {})
    : {
        totalExpiredCase: "",
        totalCuredCase: "",
        totalActiveCase: "",
        totalNewCaseToday: "",
        totalCases: null,
        totalFeedback: "",
        totalFeedbackToday: "",
      };
  //change the return to result once api has been changed
  return updatedResult as IKPIs;
};
