import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import { GetCallListApi } from "./getCallListApi";
import { templateAPI } from "./templateAPI";

export const GetAllPatientApi = (accessToken: string) => {
  
  const conf: AxiosRequestConfig = {
    method: "get",
    url: "https://api.cca.distronix.in:3443/caller/patientMaster",
    headers: {
      authorization: accessToken,
    },
  };

  const result = templateAPI(conf);
  //
  return result;
};

export const usePatientData = () => {
  const { data, error } = useSWR(null, GetCallListApi)
  return {
    patients: data,
    isLoading: !error && !data,
    isError: error,
  };
}
