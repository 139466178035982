import { AxiosRequestConfig } from "axios";
import { ISinglePatientFormData } from "./addSinglePatient";
import { templateAPI } from './templateAPI'

export const updatePatient = (
    id: string,
  patient: ISinglePatientFormData,
  accessToken: string
) => {
    const data = JSON.stringify({ ...patient });
  const config: AxiosRequestConfig = {
    method: "patch",
    url: "https://api.cca.distronix.in:3443/caller/update_citizen/" + id,
    headers: {
      authorization: accessToken,
    },
    data: patient,
  };
    const result = templateAPI(config);
    return result;
};