




import React, { Component } from 'react';
import { Card, CardDeck } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'
// import type { RootState } from "../../../app/store";
import moment from 'moment';
import './pdf.css'


function Cards(props:any) {

  return (
    
    
    <>
    
      {/* <div className='dates'>
        <div className='start_date' style={{ textAlign:'center', color: 'green', marginLeft: '20%', fontWeight: 'bold' }}>
           Date : 10-05-2021
    </div>
       
      </div><br />
      */}
    {/* <img src='/logo.png' alt='' style={{
                width: "280px",
                height: "160px", float: 'right', marginBottom: '1%'
              }} />
              <img src='/logo2.png' alt='' style={{
                width: "200px",
                height: "180px", float: 'left', marginBottom: '1%'
              }} />
       <div style={{ fontSize:50,marginTop:'10%',textAlign: 'center',fontWeight: 'bolder'}}>  Contact of Covid + ve Patients</div>
      <div className='total' style={{ color: 'blue', textAlign: 'center', fontWeight: 'bold',fontSize: 40,marginTop:'60px' }}>
        Total Number Of Patients: {props.data.size}
    </div> */}
     <div className="Header">
    <img className= 'logo_contact' src='/logo.png' alt='' />
    <div className="Title">
      <p>NEWTOWN KOLKATA DEVELOPMENT AUTHORITY</p>
      </div>
           <img className= 'logo2_contact' src='/logo2.png' alt='' />
    </div>
    <div className="Header2">
    <div className="Date">
      <p style={{fontWeight:'bold'}}>DATE : {moment().format('LL')}</p>
      </div>
    <div className="Title2">
      <p style={{fontSize:'x-large'}}>Contact of Major Disease Patients</p>
      </div>
     
      <div className="Time">
      <p style={{fontWeight:'bold'}}>TIME : {moment().format('LT')}</p>
      </div>
        </div>
    <div>

        {

          props.data ?
            <div>
              <table className="table">
                <thead style={{color:'#ff7b03',backgroundColor: '#e7e5e5',textAlign:'center'}}>
                  <tr  style={{fontWeight:"bold",fontSize:'larger'}}>
                    <th>Id</th>
                    
                    <th>{props.data.tableHeader[0]}</th>

                    <th>

                    

                    {props.data.tableHeader[1]}</th>
                    <th>{props.data.tableHeader[2]}</th>
                    <th>{props.data.tableHeader[3]}</th>
                    <th>{props.data.tableHeader[4]}</th>
                    <th>{props.data.tableHeader[5]}</th>
                    <th>{props.data.tableHeader[6]}</th>
                    <th>{props.data.tableHeader[7]}</th>
                    <th>{props.data.tableHeader[8]}</th>
                    <th>{props.data.tableHeader[9]}</th>
                    <th>{props.data.tableHeader[10]}</th>
                    <th>{props.data.tableHeader[11]}</th>
                    <th>{props.data.tableHeader[12]}</th>
                    <th>{props.data.tableHeader[13]}</th>
                    <th>{props.data.tableHeader[14]}</th>
                    <th>{props.data.tableHeader[15]}</th>
                   
                  </tr>
                </thead>
                <tbody style={{color:'grey',fontWeight:'lighter',textAlign:'center'}}> 
                  {props.data.data.map(
                    (data:any, i:number) =>
                      <tr >
                        <td>{data.id}</td>
                        <td>{data[props.data.tableHeader[0]]} </td>
                        <td> {data[props.data.tableHeader[1]]}</td>
                        <td> {data[props.data.tableHeader[2]]}</td>

                        <td > {moment(data[props.data.tableHeader[3]]).format('LL')}</td>
                        <td> {data[props.data.tableHeader[4]]}</td>
                        <td> {data[props.data.tableHeader[5]]}</td>
                        <td> {data[props.data.tableHeader[6]]}</td>
                        <td> {data[props.data.tableHeader[7]]}</td>
                        <td> {data[props.data.tableHeader[8]]}</td>
                        <td> {data[props.data.tableHeader[9]]}</td>
                        <td> {data[props.data.tableHeader[10]]}</td>
                        <td> {data[props.data.tableHeader[11]]}</td>
                        <td> {data[props.data.tableHeader[12]]}</td>
                        <td> {data[props.data.tableHeader[13]]}</td>
                        <td> {data[props.data.tableHeader[14]]}</td>
                        <td> {data[props.data.tableHeader[15]]}</td>
                        
                      </tr>
                  )}
                </tbody>
              </table>
            </div>

            : <p>Please Wait</p>
        }
      </div>
  
    </>
  
  )
}
class PdfReport extends Component {
  render() {
    return (
      <div>
        < Cards data={this.props} />
      </div>
    );
  }
}

export default PdfReport;
