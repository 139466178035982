import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridColumnHeaderParams,
  gridColumnsSelector,
  GridColumnsHeader,
  GridColumnsToolbarButton,
  GridCellParams,
} from "@material-ui/data-grid";
import moment from 'moment';
import React, { useState, useEffect, useCallback } from "react";

import "./DataGrid.css";
import style from './style.module.css'
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import MyUser from './modal';
import { Button, Modal, Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getContactData  } from "../../../api/getContactData";
import { RootState } from "../../../app/store";
import Example from "./pdf/Pdf";
// import Example from "../../../pdf/Pdf";
//import Geocode from './GoogleComponent';
import { ISinglePatientFormData } from '../../../api/addSinglePatient'

import debounce from "lodash.debounce";

export interface ISinglePatientData extends ISinglePatientFormData {
  id: string;
}


const useStyles = makeStyles({
  root: {
    border: 2,
    borderRadius: 3,
    // boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    color: "white",
    height: 48,
    justifyContent: "center",
    textAlign: "center",
  },
  rows: {
    color: "#fff",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "none",
    boxShadow: "5px 5px 5px 5px rgba(19, 8, 173, 0.3)",
    backgroundColor: "white",
    height: 200,
    width: 400,
    textAlign: "center",
    padding: 10,
  },
});

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <FormControl
        style={{ width: 200, marginRight: 20 }}
        placeholder="Search"
      /> */}
      <GridToolbarExport />
      <GridColumnsToolbarButton />
    </GridToolbarContainer>
  );
}
function newLineFixed(items:any){
   
  const newItems = items.map((item:any) =>{ 
   
    return {...item, 
      "Remarks":item["Remarks"].replace(/\n/g, ' '),
      "Patient treated at home isolation / hospital":item["Patient treated at home isolation / hospital"].replace(/\n/g, ' '),
      // "collection_date":moment(item["collection_date"]).format('LL'),
      "Test Date":moment(item["Test Date"]).format('YYYY-MM-DD')
    }
  
  
  })
   console.log(newItems)
   return newItems;
}
const tableHeader=["Name of the Positive Patients","Age","Sex","Test Date","Address (Street No/ Para)","Ward/ GP","ULB/Block","Patient Mobile No","Patient treated at home isolation / hospital","No. of family members","No of family members traced","Number of family memers tested","No of +ve pt.","No of -ve pt.","If family members found positive, whether at hospital/ Home iso","Remarks"]

export default function HeaderColumnsGrid() {
//   const { accessToken } = useSelector((state: RootState) => state.user);
  const [rowData, setRowData] = useState<any[]>([]);
  const [size, setSize] = useState<any>(0);

  
  const [state, setState] = React.useState({
    checkedB: true
  });
  const [dataType, setDataType] = React.useState("today");

  const handleChange = (event:any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if(dataType==='all'){
      setDataType('today')
    }
    else{
      setDataType('all')
    }
    console.log(dataType)
  };

  const [SearchQuery, setSearchQuery] = useState("");
  const [FilteredData, setFilteredData] = useState<ISinglePatientData[]>([]);

  const debouncedFilter = useCallback(
    () => debounce((q: string) => {
      if (q === "") {
    // console.log("filtered data count: ",FilteredData.length, "| row data count: ", rowData.length)

      setFilteredData(rowData);
      return;
    }
    const searcher = new RegExp(q, "i");

    const data = rowData.filter(
      (i) => JSON.stringify(i).match(searcher) !== null
    );
    // console.log("filtered data count: ",data.length, "| row data count: ", rowData.length)
    setFilteredData(data);
    return;
    }, 1000),
    [rowData] // will be created only once initially
  );
  const handleSearchQuery = (query: string) => {
    const call = debouncedFilter();
    setSearchQuery(query);
    call(query);
  };



  useEffect(() => {
    getContactData(dataType).then((rowData) =>{

     const arr= newLineFixed(rowData?.data?.data)
      setSize(rowData?.data?.total)
     
      if (!!arr) {
        setRowData(arr);
        setFilteredData(arr);
    }
    }
     
    );
  }, [dataType]);

  const classes = useStyles();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <>
      {/* <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "35%" }}
          >
            Sanitization Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="patient_info" style={{ textAlign: "center" }}>
              <p>NAME : Mr. Akash Roy</p>
              <p>Phone Number : 9239186399</p>
              <p>Age : 38</p>
              <p>Sex : Male</p>
              <p>Address : Flat No. 20B , Newtown , Action Area: III</p>
              <p>Status: Active</p>
              <p>Place Of Admisson: Home Isolation</p>
              <p>Last Tested : 12-04-2021</p>
              <p>No. of Direct Contact : NA</p>
              <p>Names of Direct Contact : NA</p>
              <p>Test Result of Direct Contact : NA</p>
              <p>Indirect Contact : NA</p>
              <p>Images:</p>
              <img src="img1.jpg" alt="G1s" width="20%" />
              <img src="img1.jpg" alt="G1s" width="20%" />
              <img src="img1.jpg" alt="G1s" width="20%" />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ marginRight: "45%" }}>
          <Button variant="info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         centered
         scrollable={true}
         style={{marginRight:'45%',overflow:'scroll'}}
         //scrollable={true}
        
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter"  style={{whiteSpace:'nowrap'}}>
            Contact Tracing data Sheet

          </Modal.Title>
        </Modal.Header>
        <Modal.Body  style={{border:'1px solid blue',margin:'5px 5px'}}>
        
        <Button variant="danger" onClick={handleClose} style={{float:'right',marginTop:'145px'}}> 
            Close
          </Button>
          <h5 style={{whiteSpace:'nowrap',marginTop:'10px',color:'blue'}}>Do You Want To Save PDF ?</h5>
        <Example data={rowData} size={size} tableHeader={tableHeader}/>
        
        </Modal.Body>
        
      </Modal>

      <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="secondary"
          />
        }
        label="Today Data"
      />
    </FormGroup>

     
       <Form.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
      
        <FormControl
          style={{ width: 200, marginLeft: 10 }}
          placeholder="Search"
          value={SearchQuery}
          onChange={(e) => handleSearchQuery(e.target.value)}
        />
      
      <button 
       type='button' 
       className="btn"
       style={{backgroundColor:'blue',color:'white',padding:'5px',marginLeft:20,borderRadius:'10px'}}
       onClick={handleShow}>
           Export Report
       </button>

      </Form.Group>

      <div
        className={classes.root}
        style={{
          height: 590,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        <DataGrid
          columns={[
            {
              field: "id",
              hide: false,
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"ID"}</strong>
              ),
              headerAlign: "center",
            },
            {
              field: "Name of the Positive Patients",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient Name"}</strong>
              ),

              headerAlign: "center",
              width: 150,
            },
            {
              field: "Sex",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Sex"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },
            {
              field: "Age",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Age"}</strong>
              ),

              headerAlign: "center",
              width: 100,
            },

            {
              field: "Test Date",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Test Date"}</strong>
              ),
              width: 160,
              align: "center",
              headerAlign: "center",
              // renderCell: (params: GridCellParams) => {
              //   return (
              //     <strong>
              //       {" "}
              //       {moment(
              //         params.getValue("Test Date")?.toString()
              //       ).format("ll")}
              //     </strong>
              //   );
              // },
            },
            {
              field: "Address (Street No/ Para)",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Address in New Town"}</strong>
              ),

              headerAlign: "center",

              width: 250,
            },
            {
              field: "Ward/ GP",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Ward/ GP"}</strong>
              ),

              headerAlign: "center",

              width: 150,
            },
            {
              field: "ULB/Block",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"ULB/Block"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "Patient Mobile No",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Number"}</strong>
              ),

              headerAlign: "center",
              width: 140,
            },


            {
              field: "Patient treated at home isolation / hospital",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Patient treated at home isolation / hospital"}</strong>
              ),
              width: 350,
              headerAlign: "center",
            },
            {
              field: "No. of family members",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"No. of family members"}</strong>
              ),
              width: 300,
              headerAlign: "center",
            },
            {
              field: "No. of family members traced",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"No. of family members traced"}</strong>
              ),
              width: 300,
              headerAlign: "center",
            },
            {
              field: "No. of family members tested",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"No. of family members tested"}</strong>
              ),
              width: 300,
              headerAlign: "center",
            },

            {
              field: "No of +ve pt",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"No of +ve pt"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "No of -ve pt",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"No of -ve pt"}</strong>
              ),
              width: 150,
              headerAlign: "center",
            },
            {
              field: "If family members found positive, whether at hospital/ Home iso",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff", overflowWrap: "break-word"
                }}>{"If family members found positive, whether at hospital/ Home iso"}</strong>
              ),
              width: 500,
              headerAlign: "center",
            },
            {
              field: "Remarks",
              renderHeader: (params: GridColumnHeaderParams) => (
                <strong style={{ color: "#fff" }}>{"Remarks"}</strong>
              ),
              width: 170,
              headerAlign: "center",
            },
            // {
            //   field: "action",
            //   renderHeader: (params: GridColumnHeaderParams) => (
            //     <strong style={{ color: "#fff" }}>{"Action"}</strong>
            //   ),
            //   width: 150,
            //   headerAlign: "center",
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <FontAwesomeIcon
            //           icon={faEye}
            //           color="blue"
            //           size="1x"
            //           onClick={handleShow}
            //         />
            //       </div>
            //     );
            //   },
            // },
          ]}
          rows={FilteredData}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
