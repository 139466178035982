export const getbgcolor = (s?: string) => {
  switch (s) {
    case "ACTIVE":
      return "yellow";
    case "CURED":
      return "green";
    case "EXPIRED":
      return "red";
    default:
      return "white";
  }
};